import { BrowserRouter as Router, Route } from "react-router-dom";
import React from 'react';
import LoginContainer from "./components/AuthComponent";
import FilesContainer from "./components/FilesComponent";
import StatsContainer from "./components/StatsComponent";
import ResetPasswordContainer from "./components/ResetPasswordComponent";
import ShareLinkContainer from "./components/ShareLinkComponent";

function App() {
	return (
		<div>
			<Router>
				<Route path="/" component={LoginContainer} exact />
				<Route path="/files" component={FilesContainer} exact />
				<Route path="/stats" component={StatsContainer} exact />
				<Route path="/reset_password" component={ResetPasswordContainer} exact />
				<Route path="/share_link" component={ShareLinkContainer} />
			</Router>
		</div>
	);
}

export default App;
