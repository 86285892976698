import { Link } from "react-router-dom";
import types from "../../types";
import { getTrans as t } from '../../translations';

const NavBar = (props) => {

    return (
        <>
            {/* <!-- Preloader --> */}
            {
                !props.all_files.length ?
                    <div className="preloader flex-column justify-content-center align-items-center">
                        <img className="animation__shake" src="static/assets/dist/img/logo.png" alt="iCubicle" height="60" width="60" />
                        <p>{t('We are loading your files. Please wait...', props.auth.language)}</p>
                    </div>
                    : null
            }
            {/* <!-- Navbar --> */}
            <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
                <div className="container">
                    <Link to="files" className="navbar-brand">
                        {/* <img src="static/assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: 0.8}} /> */}
                        <span className="brand-text font-weight-light">File Manager</span>
                    </Link>

                    {/* <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}

                    {/* <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a href="index3.html" className="nav-link">Home</a>
                            </li>
                            <li className="nav-item">
                                <Link to="#" className="nav-link">Contact</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link id="dropdownSubMenu1" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Dropdown</Link>
                                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                                    <li><Link to="#" className="dropdown-item">Some action </Link></li>
                                    <li><Link to="#" className="dropdown-item">Some other action</Link></li>

                                    <li className="dropdown-divider"></li>

                                    <li className="dropdown-submenu dropdown-hover">
                                        <Link id="dropdownSubMenu2" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">Hover for action</Link>
                                        <ul aria-labelledby="dropdownSubMenu2" className="dropdown-menu border-0 shadow">
                                            <li>
                                                <a tabindex="-1" href="#" className="dropdown-item">level 2</a>
                                            </li>

                                            <li className="dropdown-submenu">
                                                <a id="dropdownSubMenu3" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="dropdown-item dropdown-toggle">level 2</a>
                                                <ul aria-labelledby="dropdownSubMenu3" className="dropdown-menu border-0 shadow">
                                                    <li><Link to="#" className="dropdown-item">3rd level</Link></li>
                                                    <li><Link to="#" className="dropdown-item">3rd level</Link></li>
                                                </ul>
                                            </li>

                                            <li><Link to="#" className="dropdown-item">level 2</Link></li>
                                            <li><Link to="#" className="dropdown-item">level 2</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <form className="form-inline ml-0 ml-md-3">
                            <div className="input-group input-group-sm">
                                <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button className="btn btn-navbar" type="submit">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div> */}

                    {/* <!-- Right navbar links --> */}
                    <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                        {/* <!-- Messages Dropdown Menu --> */}
                        <li className="nav-item dropdown">
                            <Link to="#" className="nav-link" data-toggle="dropdown">
                                <i className="fa fa-globe"></i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <Link to="#" onClick={() => props.changeLanguage('en')} className="dropdown-item"> English - en </Link>
                                <div className="dropdown-divider"></div>
                                <Link to="#" onClick={() => props.changeLanguage('fr')} className="dropdown-item"> French - fr </Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to="#" className="nav-link" data-toggle="dropdown">
                                <i className="fa fa-cogs"></i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <span className="dropdown-item dropdown-header">{t('Other Modules', props.auth.language)}</span>
                                <div className="dropdown-divider"></div>
                                <a href={`https://chat.htu.edu.gh?q=${props.user.token}`} target="chat" className="dropdown-item">
                                    <i className="far fa-comment mr-2"></i> Chat
                                    <span className="float-right text-muted text-sm"><i className="fas fa-arrow-right"></i></span>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a href={`https://intranet.htu.edu.gh?q=${props.user.token}`} target="file_manager" className="dropdown-item">
                                    <i className="far fa-file-word mr-2"></i> Correspondence System
                                    <span className="float-right text-muted text-sm"><i className="fas fa-arrow-right"></i></span>
                                </a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/stats">
                                <i className="fas fa-tachometer-alt"></i>
                            </Link>
                        </li>
                        {/* <!-- Notifications Dropdown Menu --> */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link" data-toggle="dropdown" to="#">
                                <i className="fa fa-user"></i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <div className="dropdown-divider"></div>
                                <Link to="#" className="dropdown-item">
                                    <div className="media">
                                        <img alt="img" src={props.user.photo !== '' && props.user.photo !== null ? types.SERVER_URL + 'static/contacts/' + props.user.photo : "static/assets/dist/img/user1-128x128.jpg"}
                                            className="img-size-50 mr-3 img-circle" />
                                        <div className="media-body">
                                            <h3 className="dropdown-item-title">
                                                {props.user.first_name} {props.user.last_name}
                                                {/* <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span> */}
                                            </h3>
                                            <p className="text-sm">{props.user.email}</p>
                                        </div>
                                    </div>
                                </Link>
                                <div className="dropdown-divider"></div>
                                <Link to="#" onClick={props.logout} className="dropdown-item dropdown-footer">Logout</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* <!-- /.navbar --> */}
        </>
    )
}

export default NavBar