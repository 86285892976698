import React, { Component } from 'react'
import ShareLink from './ShareLink'
import { connect } from 'react-redux'
import {
    serverLoad,
    serverGetLinkFiles,
    serverDeleteLinkFiles,
    serverRenameLinkFile,
    serverUploadLinkFiles,
    serverGetLinkChildren,
    serverUploadLinkUrl,
    serverCreateLinkFile,
    serverCreateLinkFolder
} from '../../actions/FilesAction';
import { serverChangeLanguage } from './../../actions/AuthAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import types from '../../types';
import fileDownload from 'js-file-download';
import axios from 'axios'

const $ = require("jquery");

// let bread_output = [];

class ShareLinkContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valid_link: 0,
            link: null,
            breadcrumbs: [],
            file_list: [],
            file_tree: [],
            files_selected: [],
            selected_files: {},
            file_details: {},
            current_directory: null,
            document_config: {},
            received: 1,
            copied_items: [],
            floating_directory: null,
            file_type_list: [],
        }

        this.handleChange = this.handleChange.bind(this);
        this.selectFiles = this.selectFiles.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
        this.uploadUrl = this.uploadUrl.bind(this);
        this.createFile = this.createFile.bind(this);
        this.createFolder = this.createFolder.bind(this);
        this.trashFiles = this.trashFiles.bind(this);
        this.renameFile = this.renameFile.bind(this);
        this.downloadFiles = this.downloadFiles.bind(this);
        this.setFile = this.setFile.bind(this);
        this.fileType = this.fileType.bind(this);
        this.reload = this.reload.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unSelectAll = this.unSelectAll.bind(this);
        this.goHome = this.goHome.bind(this);
        this.checkPrivilege = this.checkPrivilege.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const shareLink = urlParams.get('i');
        if (shareLink) {
            this.setState({ valid_link: 1, link: shareLink });
            window.localStorage.setItem('link', shareLink);
            this.props.serverGetLinkFiles(shareLink);
        } else {
            // check local storage
            if (window.localStorage.getItem('link')) {
                this.setState({ valid_link: 1, link: window.localStorage.getItem('link') });
                this.props.serverGetLinkFiles(window.localStorage.getItem('link'));
            }
        }

        this.fileType("all");
    }

    componentDidUpdate(prevProps, prevState) { // this is called anytime there is changes in the props/state
        if (prevProps.files.link_files !== this.props.files.link_files) {
            if (!this.state.breadcrumbs.length) {
                this.setState({ file_list: this.props.files.link_files, file_details: {}, files_selected: [], selected_files: {} });
                $("#dismiss_rename_file_modal").click()
            }
        }

        if (prevProps.files.link_children !== this.props.files.link_children) {
            if (this.state.breadcrumbs.length) {
                this.setState({ file_list: this.props.files.link_children, file_details: {}, files_selected: [], selected_files: {} });
                $("#dismiss_upload_files_modal").click();
                $("#dismiss_upload_url_modal").click();
                $("#dismiss_create_word_modal").click();
                $("#dismiss_create_powerpoint_modal").click();
                $("#dismiss_create_excel_modal").click();
                $("#dismiss_create_folder_modal").click();
                $("#dismiss_rename_file_modal").click()
            }
        }


    }

    selectFiles = (data) => {

        let newState = { ...this.state.selected_files };

        if (newState[data.fid]) {
            delete newState[data.fid];
        } else {
            newState[data.fid] = data;
        }

        this.setState({
            selected_files: newState
        });

        let results = Object.values(newState);

        this.setState({ files_selected: results });

    }

    enterFolder = (data) => {

        // check for password
        let pass = this.checkPassword([data]);
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === data.password + "") {

                    let breadcrumbs = [...this.state.breadcrumbs, data];
                    this.setState({ breadcrumbs: breadcrumbs, current_directory: data.fid, files_selected: [], selected_files: {} });
                    this.props.serverGetLinkChildren(data, this.state.link);

                } else {
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return
                }
            }
        } else {
            let breadcrumbs = [...this.state.breadcrumbs, data];
            this.setState({ breadcrumbs: breadcrumbs, current_directory: data.fid, files_selected: [], selected_files: {} });
            this.props.serverGetLinkChildren(data, this.state.link);

        }
    }

    clickBreadCrumbs = (index) => {
        let selected_breadcrumbs = this.state.breadcrumbs[index];
        this.props.serverGetLinkChildren(selected_breadcrumbs, this.state.link);
        let breadcrumbs = [...this.state.breadcrumbs, selected_breadcrumbs];
        breadcrumbs = breadcrumbs.slice(0, index + 1);
        this.setState({ breadcrumbs: breadcrumbs, current_directory: selected_breadcrumbs.fid, files_selected: [], selected_files: {} });
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            file_details: {                   // object that we want to update
                ...prevState.file_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));
    }

    uploadFiles = () => {
        // check entry
        if (!this.state.file_details.upload_files) {
            toast.error("Please select at least a single file to upload.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let file_type_list = [...this.state.file_type_list];
        let all_file_types = [];
        file_type_list.forEach(element => {
            all_file_types.push(element.value);
        });

        // Create an object of formData
        const formData = new FormData();

        if (this.state.file_details.upload_files) {

            for (let index = 0; index < this.state.file_details.upload_files.length; index++) {
                const element = this.state.file_details.upload_files[index];

                if (this.state.received) {
                    let file_name = element.name;
                    let file_size = (element.size / 1000000).toFixed(2);
                    let ext = file_name.split('.').pop();
                    ext = "." + ext;

                    if (this.state.breadcrumbs[0].upload_acceptable_file !== "" && !this.state.breadcrumbs[0].upload_acceptable_file.includes(ext)) {
                        toast.error("You are attempting to upload a file that is not allowed in this directory", {
                            position: 'top-center',
                            autoClose: 3000
                        });

                        return
                    }

                    if (this.state.breadcrumbs[0].upload_size_limit !== "" && (file_size > parseFloat(this.state.breadcrumbs[0].upload_size_limit))) {
                        toast.error("You can only upload files with size less than " + this.state.breadcrumbs[0].upload_size_limit + " MB", {
                            position: 'top-center',
                            autoClose: 3000
                        });

                        return
                    }

                }

                formData.append("uploaded_files[]", element);
            }

            formData.append("current_directory", this.state.current_directory);

            this.props.serverUploadLinkFiles(formData, this.state.link)
        }
    }

    uploadUrl = () => {
        // check entry
        if (!this.state.file_details.upload_url) {
            toast.error("Please enter a valid url.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let data = {
            "url": this.state.file_details.upload_url,
            "current_directory": this.state.current_directory
        }

        this.props.serverUploadLinkUrl(data, this.state.link);
    }

    createFile = (doc_type) => {
        // check entry
        if (!this.state.file_details.create_doc_file_name) {
            toast.error("Please enter a file name.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let owner = this.state.breadcrumbs[0].owner;

        let data = {
            "file_name": this.state.file_details.create_doc_file_name,
            "current_directory": this.state.current_directory,
            "doc_type": doc_type,
            "owner": owner
        }

        this.props.serverCreateLinkFile(data, this.state.link);
    }

    createFolder = () => {
        // check entry
        if (!this.state.file_details.create_folder_name) {
            toast.error("Please enter folder name.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let owner = this.state.breadcrumbs[0].owner;

        let data = {
            "folder_name": this.state.file_details.create_folder_name,
            "current_directory": this.state.current_directory,
            "owner": owner
        }

        this.props.serverCreateLinkFolder(data, this.state.link)
    }

    renameFile = () => {
        // check for password
        let pass = this.checkPassword();
        let state = this.checkPrivilege("rename");
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === this.state.files_selected[0].password) {
                    // check for privileges
                    if (state === 1) {
                        if (!this.state.file_details.rename_file_name) {
                            toast.error("Please enter new file/folder name.", {
                                position: 'top-center',
                                autoClose: 3000
                            });

                            return
                        }

                        let data = {
                            "file_name": this.state.file_details.rename_file_name,
                            "fid": this.state.files_selected[0].fid
                        }

                        this.props.serverRenameLinkFile(data, this.state.link)
                    } else {
                        toast.error("Sorry, you dont have permission to rename selected file", {
                            position: 'top-center',
                            autoClose: 3000
                        });
                    }
                } else {
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });
                }
            }
        } else {
            // check for privileges
            if (state === 1) {
                if (!this.state.file_details.rename_file_name) {
                    toast.error("Please enter new file/folder name.", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return
                }

                let data = {
                    "file_name": this.state.file_details.rename_file_name,
                    "fid": this.state.files_selected[0].fid
                }

                this.props.serverRenameLinkFile(data, this.state.link);
            } else {
                toast.error("Sorry, you dont have permission to rename selected file", {
                    position: 'top-center',
                    autoClose: 3000
                });
            }
        }
    }

    downloadFiles = async () => {

        // check for password
        let pass = this.checkPassword();
        let state = this.checkPrivilege("download");
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === this.state.files_selected[0].password) {
                    // check for privileges
                    if (state === 1) {
                        this.state.files_selected.forEach(element => {
                            if (element.type === "file") {
                                axios.get(`${types.SERVER_URL}static/file_manager/files/${element.fid}${element.filetype}`, {
                                    responseType: 'blob',
                                }).then(res => {
                                    fileDownload(res.data, `${element.filename}${element.filetype}`);
                                });
                            }
                        });
                    } else {
                        toast.error("Sorry, you dont have permission to download selected file", {
                            position: 'top-center',
                            autoClose: 3000
                        });
                    }
                } else {
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });
                }
            }
        } else {
            // check for privileges
            if (state === 1) {
                this.state.files_selected.forEach(element => {
                    if (element.type === "file") {
                        axios.get(`${types.SERVER_URL}static/file_manager/files/${element.fid}${element.filetype}`, {
                            responseType: 'blob',
                        }).then(res => {
                            fileDownload(res.data, `${element.filename}${element.filetype}`);
                        });
                    }
                });
            }
        }
    }

    setFile = (files_selected) => {

        // let files_selected = this.state.files_selected[0];

        let kind = "";
        let document_config = {};
        if (files_selected.filetype === '.docx') {
            kind = "word";
        } else if (files_selected.filetype === '.pptx') {
            kind = "slide";
        } else if (files_selected.filetype === '.xlsx' || files_selected.filetype === ".csv") {
            kind = "cell";
        }

        if (kind !== "") {
            document_config = {
                "document": {
                    "fileType": files_selected.filetype.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": files_selected.filename.replace(/&/g,""),
                    "url": `${types.SERVER_URL}static/file_manager/files/${files_selected.fid.toString()}${files_selected.filetype}`,
                    "user": {
                        "id": Math.floor(Math.random() * 999999999999) + "",
                        "name": `Anonymous`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}fm/documentserver_callback?file=${files_selected.fid.toString()}${files_selected.filetype}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }
        }

        // check for password
        let pass = this.checkPassword([files_selected]);
        let state = this.checkPrivilege("preview");
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === files_selected.password + "") {
                    // check for privileges
                    if (state === 0) {
                        $("#dismiss_view_file_modal").click();
                        toast.error("Sorry, you dont have permission to preview selected file", {
                            position: 'top-center',
                            autoClose: 3000
                        });

                        return
                    }
                } else {
                    $("#dismiss_view_file_modal").click();
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return
                }
            } else {
                $("#dismiss_view_file_modal").click();
                return
            }
        } else {
            // check for privileges
            if (state === 0) {
                $("#dismiss_view_file_modal").click();
                toast.error("Sorry, you dont have permission to preview selected file", {
                    position: 'top-center',
                    autoClose: 3000
                });

                return
            }
        }

        this.setState({ document_config: document_config, files_selected: [files_selected], selected_files: { [files_selected.fid]: { files_selected } } });
        
        if (kind !== "") {
            // open in new tab for office documents
            window.open(encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(document_config)}`), files_selected.filename);
            this.unSelectAll();
            $("#dismiss_view_file_modal").click();
        }
    }

    fileType = (type) => {

        let image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        let video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        let audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        let compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        let word_type_list = ['.doc', '.dot', '.wbk', '.docx', '.docm', '.dotx', 'dotm', 'docb'];

        let excel_type_list = ['.xls', '.xlt', '.xlm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlsb', '.xla', '.xlam', '.xll', '.xlw', '.csv'];

        let powerpoint_type_list = ['.ppt', '.pot', '.pps', '.pptx', '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.sldx', '.sldm'];

        let access_type_list = ['.accdb', '.accde', '.accdt', '.accdr'];

        let doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];

        let all_file_types = image_type_list.concat(video_type_list).concat(audio_type_list).concat(word_type_list).concat(excel_type_list).concat(powerpoint_type_list).concat(access_type_list).concat(compressed_type_list).concat(doc_type_list);

        if (type === "all") {
            let file_type_list = [];
            all_file_types.forEach(element => {
                file_type_list.push({ "label": element, "value": element })
            });
            this.setState({ file_type_list: file_type_list });
        }

        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    reload = () => {

        if (!this.state.breadcrumbs.length) {
            this.props.serverGetLinkFiles(this.state.link);
        } else {
            this.props.serverGetLinkChildren(this.state.breadcrumbs[this.state.breadcrumbs.length - 1], this.state.link);
        }
    }

    selectAll = () => {
        let newState = {};

        let file_list = [...this.state.file_list];

        file_list.forEach(element => {
            newState[element.fid] = element;

        });

        let results = Object.values(newState);

        this.setState({ selected_files: newState, files_selected: results });
    }

    unSelectAll = () => {
        this.setState({ selected_files: {}, files_selected: [] });
    }

    trashFiles = () => {
        // check for password
        let pass = this.checkPassword();
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === this.state.files_selected[0].password) {
                    // check for privileges
                    if (window.confirm("Are you sure you want to delete selected files?") === true) {

                        let selected_files = { ...this.state.selected_files };

                        this.props.serverDeleteLinkFiles(selected_files, this.state.link);
                    }
                    return;
                } else {
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return;
                }
            }
        } else {
            // check for privileges
            if (window.confirm("Are you sure you want to delete selected files?") === true) {

                let selected_files = { ...this.state.selected_files };

                this.props.serverDeleteLinkFiles(selected_files, this.state.link);
            }
        }
    }

    goHome = () => {

        this.props.serverGetLinkFiles(this.state.link);

        this.setState({ breadcrumbs: [], current_directory: null });
    }

    checkPrivilege = (privilege) => {
        if (this.state.received) {

            let files_selected = [...this.state.files_selected];

            switch (privilege) {
                case 'upload':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'upload'")) {
                            return 1
                        }
                    }
                    return 0;

                case 'new_folder':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'new_folder'")) {
                            return 1
                        }
                    }
                    return 0;

                case 'download':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'download'")) {
                            return 1
                        }
                    }

                    if (files_selected.length) {
                        if (files_selected[0].actions === "" || files_selected[0].actions.includes("'value': 'download'")) {
                            return 1
                        }
                    }

                    return 0;

                case 'rename':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'rename'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    if (files_selected.length) {
                        if (files_selected[0].actions === "" || files_selected[0].actions.includes("'value': 'rename'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    return 1;

                case 'preview':

                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'preview'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    if (files_selected.length) {
                        if (files_selected[0].actions === "" || files_selected[0].actions.includes("'value': 'preview'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    return 1

                default:
                    return 0;
            }
        }
        return 1
    }

    checkPassword = (file) => {

        if (this.state.received) {

            let files_selected = [...this.state.files_selected];

            if (!files_selected.length && typeof (file) !== "undefined") {
                files_selected = file;
            }

            if (files_selected.length && !this.state.breadcrumbs.length) {
                if (files_selected[0].password !== "") {
                    return 0
                }
            }
        }

        return 1
    }

    changeLanguage = (language) => {
        this.props.serverChangeLanguage(language);   
    }

    render() {
        return (
            <ShareLink
                c_state={{ ...this.state }}
                auth={this.props.auth}
                handleChange={this.handleChange}
                files={this.props.files}
                select_files={this.selectFiles}
                enter_folder={this.enterFolder}
                click_breadcrumbs={this.clickBreadCrumbs}
                upload_files={this.uploadFiles}
                upload_url={this.uploadUrl}
                create_file={this.createFile}
                create_folder={this.createFolder}
                trash_files={this.trashFiles}
                rename_file={this.renameFile}
                download_files={this.downloadFiles}
                set_file={this.setFile}
                file_type={this.fileType}
                reload={this.reload}
                select_all={this.selectAll}
                unselect_all={this.unSelectAll}
                go_home={this.goHome}
                check_privilege={this.checkPrivilege}
                changeLanguage={this.changeLanguage}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        files: state.files,
        auth: state.auth
    }
}

export default connect(mapStateToProps, {
    serverLoad,
    serverGetLinkFiles,
    serverDeleteLinkFiles,
    serverRenameLinkFile,
    serverUploadLinkFiles,
    serverGetLinkChildren,
    serverUploadLinkUrl,
    serverCreateLinkFile,
    serverCreateLinkFolder,
    serverChangeLanguage
})(ShareLinkContainer)
