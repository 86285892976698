const en = {
    'Upload Files': 'Upload Files',
    'File': 'File',
    'My Computer': 'My Computer',
    'URL': 'URL',
    'Word Document': 'Word Document',
    'Powerpoint': 'Powerpoint',
    'Spreadsheet': 'Spreadsheet',
    'Folder': 'Folder',
    'Copy': 'Copy',
    'Move': 'Move',
    'Paste': 'Paste',
    'Download': 'Download',
    'Move To Trash': 'Move To Trash',
    'Delete': 'Delete',
    'We are loading your files. Please wait...': 'We are loading your files. Please wait...',
    'Other Modules': 'Other Modules',
    'Rename': 'Rename',
    'View': 'View',
    'Share (In App)': 'Share (In App)',
    'Share (Via Link)': 'Share (Via Link)',
    'Restore': 'Restore',
    'My Folders': 'My Folders',
    'Home': 'Home',
    'Trash': 'Trash',
    'Shared': 'Shared',
    'Received': 'Received',
    'Search': 'Search',
    'Refresh': 'Refresh',
    'Select All': 'Select All',
    'Unselect All': 'Unselect All',
    'View Trash': 'View Trash',
    'Empty Trash': 'Empty Trash',
    'Shared Files': 'Shared Files',
    'Received Files': 'Received Files',
    'Search Term': 'Search Term',
    'File Extension': 'File Extension',
    'Select': 'Select',
    'From (Date Created)': 'From (Date Created)',
    'To (Date Created)': 'To (Date Created)',
    'Tags': 'Tags',
    'Filter': 'Filter',
    'All': 'All',
    'File Name': 'File Name',
    'Folder Name': 'Folder Name',
    'File Contents': 'File Contents',
    'Name': 'Name',
    'Size': 'Size',
    'Date Shared': 'Date Shared',
    'Date Received': 'Date Received',
    'Last Modified': 'Last Modified',
    'Upload File': 'Upload File',
    'Select File': 'Select File',
    'Close': 'Close',
    'Save changes': 'Save changes',
    'Upload File (URL)': 'Upload File (URL)',
    'Enter URL location of file to upload': 'Enter URL location of file to upload',
    'Create Word Document': 'Create Word Document',
    'Enter File Name': 'Enter File Name',
    'Create Excel Document': 'Create Excel Document',
    'Create Powerpoint Document': 'Create Powerpoint Document',
    'Create New Folder': 'Create New Folder',
    'Enter Foldr Name': 'Enter Folder Name',
    'Rename File/Folder': 'Rename File/Folder',
    'Enter New File/Folder Name': 'Enter New File/Folder Name',
    'View File': 'View File',
    'This file cannot be previewed': 'This file cannot be previewed',
    'Click here to download': 'Click here to download',
    'Share With': 'Share With',
    'Select Contact': 'Select Contact',
    'Expiry Date': 'Expiry Date',
    'Maximum Access': 'Maximum Access',
    'Password': 'Password',
    'Privileges': 'Privileges',
    'Upload Size Limit (MB)': 'Upload Size Limit (MB)',
    'Acceptable Files': 'Acceptable Files',
    'File Note': 'File Note',
    'Select an expiry date for the files you wish to share. Recipients will not be able to access the file after expiry date. Leave empty for no expiry date': 'Select an expiry date for the files you wish to share. Recipients will not be able to access the file after expiry date. Leave empty for no expiry date',
    'You can enter a number to specify the number of times the files can be accessed by the recipients': 'You can enter a number to specify the number of times the files can be accessed by the recipients',
    'Set a password on the files you wish to share. Leave empty for no password': 'Set a password on the files you wish to share. Leave empty for no password',
    'You can set custom privileges for the files/folders you share. Leave empty to allow for all privileges': 'You can set custom privileges for the files/folders you share. Leave empty to allow for all privileges',
    'Any options chosen in this list will allow the recipients to only upload files with the selected extensions. Leave empty to allow for upload of all types of files': 'Any options chosen in this list will allow the recipients to only upload files with the selected extensions. Leave empty to allow for upload of all types of files',
    'This field indicates that the recipients will not be able to upload files with size greater than the one specified in this field. Leave empty for unlimited file size': 'This field indicates that the recipients will not be able to upload files with size greater than the one specified in this field. Leave empty for unlimited file size',
    'Share Info': 'Share Info',
    'Unshare': 'Unshare',
    'Locate This File': 'Locate This File',
    'File/Folder Name': 'File/Folder Name',
    'Link Generated': 'Link Generated',
    'Images': 'Images',
    'Audios': 'Audios',
    'Videos': 'Videos',
    'Documents': 'Documents',
    'Compressed Files': 'Compressed Files',
    'Other Files': 'Other Files',
    'Folders': 'Folders',
    'File Quota': 'File Quota',
    'Left': 'Left',
    'Oops! Something went wrong.': 'Oops! Something went wrong.',
    'The page you are trying to access appears to be invalid. Please contact your administrator or request for a new link.': 'The page you are trying to access appears to be invalid. Please contact your administrator or request for a new link.'
  };
  export default en;