import React, { Component } from 'react'

import Files from './Files';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverGetContacts } from '../../actions/ContactsAction';
import {
    serverLoad, serverGetAllFiles, serverUploadFiles, serverUploadUrl, serverCreateFile, serverCreateFolder,
    serverDeleteFiles, serverRenameFile, serverCopyFiles, serverMoveFiles, serverShareInApp, serverShareViaLink,
    serverGetAllTrash, serverDeleteTrash, serverRestoreTrash, serverGetSharedFiles, serverUnshareFiles,
    serverUpdateShareRules, serverGetReceivedFiles, serverGetTags, serverDoSearch, serverGetReceivedChildren
} from '../../actions/FilesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import types from '../../types';
import fileDownload from 'js-file-download';
import axios from 'axios'

const $ = require("jquery");

let bread_output = [];

class FilesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_list: [],
            breadcrumbs: [],
            file_list: [],
            file_tree: [],
            files_selected: [],
            selected_files: {},
            file_details: {},
            current_directory: null,
            document_config: {},
            copy: 0,
            move: 0,
            trash: 0,
            shared: 0,
            received: 0,
            copied_items: [],
            floating_directory: null,
            file_type_list: [],
            show_search: 0,
            search_details: {
                filter: "all"
            },
            tags_dropdown: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.selectFiles = this.selectFiles.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.enterFolderAlt = this.enterFolderAlt.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
        this.uploadUrl = this.uploadUrl.bind(this);
        this.createFile = this.createFile.bind(this);
        this.createFolder = this.createFolder.bind(this);
        this.trashFiles = this.trashFiles.bind(this);
        this.renameFile = this.renameFile.bind(this);
        this.downloadFiles = this.downloadFiles.bind(this);
        this.setFile = this.setFile.bind(this);
        this.fileType = this.fileType.bind(this);
        this.copy = this.copy.bind(this);
        this.paste = this.paste.bind(this);
        this.shareInApp = this.shareInApp.bind(this);
        this.shareViaLink = this.shareViaLink.bind(this);
        this.reload = this.reload.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unSelectAll = this.unSelectAll.bind(this);
        this.viewTrash = this.viewTrash.bind(this);
        this.goHome = this.goHome.bind(this);
        this.deleteFiles = this.deleteFiles.bind(this);
        this.emptyTrash = this.emptyTrash.bind(this);
        this.restore = this.restore.bind(this);
        this.sharedFiles = this.sharedFiles.bind(this);
        this.shareInfo = this.shareInfo.bind(this);
        this.unshareFiles = this.unshareFiles.bind(this);
        this.goToSource = this.goToSource.bind(this);
        this.updateShareRules = this.updateShareRules.bind(this);
        this.receivedFiles = this.receivedFiles.bind(this);
        this.checkPrivilege = this.checkPrivilege.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.clickTree = this.clickTree.bind(this);
        this.formBreadcrumbs = this.formBreadcrumbs.bind(this);
        this.showSearch = this.showSearch.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.mainContextMenu = this.mainContextMenu.bind(this);
        this.copyLink = this.copyLink.bind(this);
    }

    componentDidMount() {

        let token = localStorage.getItem("token");
        if (!token) {
            window.location.href = '/';
        }

        if (!this.props.files.all_files.length) {
            this.props.serverGetAllFiles(token);
        } else {

            if (!this.state.breadcrumbs.length) {

                let parent = this.props.files.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.files.all_files.filter(item => item.parent + '' === parent[0].fid + '');
                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs, file_tree: this.props.files.tree, current_directory: parent[0].fid });
            }
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(token);
        } else {
            // let arr = [{ "value": "Myself", "label": "Myself" }]
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contact_list: arr });
        }

        if (!this.props.files.tags.length) {
            this.props.serverGetTags(token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.files.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        window.document.addEventListener("contextmenu", (event) => {
            event.preventDefault()
        });

        this.fileType("all");
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.contacts !== this.props.contacts) {
            // let arr = [{ "value": this.props.auth.user.contact_id, "label": "Myself" }]
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contact_list: arr });
        }

        if (prevProps.files.all_files !== this.props.files.all_files) {

            if (!this.state.breadcrumbs.length && !this.state.received && !this.state.shared) {

                let parent = this.props.files.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.files.all_files.filter(item => item.parent + '' === parent[0].fid + '');
                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                this.setState({ file_list: file_list, breadcrumbs: breadcrumbs, file_tree: this.props.files.tree, current_directory: parent[0].fid, file_details: {}, files_selected: [], selected_files: {}, });
            } else {

                if (!this.state.shared && !this.state.received) {

                    let parent = this.props.files.all_files.filter(item => item.fid === this.state.current_directory);

                    let file_list = [];

                    if (parent.length) {
                        file_list = this.props.files.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                        this.setState({ file_list: file_list, file_tree: this.props.files.tree, current_directory: parent[0].fid, file_details: {}, files_selected: [], selected_files: {} });
                    }
                }
            }

            $("#dismiss_upload_files_modal").click();
            $("#dismiss_upload_url_modal").click();
            $("#dismiss_create_word_modal").click();
            $("#dismiss_create_powerpoint_modal").click();
            $("#dismiss_create_excel_modal").click();
            $("#dismiss_create_folder_modal").click();
            $("#dismiss_rename_file_modal").click()
            $("#dismiss_share_in_app_modal").click();

            this.clearModalContents();
        }

        if (prevProps.files.file_link !== this.props.files.file_link) {
            navigator.clipboard.writeText(types.SERVER_URL_ALT + 'share_link?i=' + this.props.files.file_link);
            $("#dismiss_share_via_link_modal").click();
        }

        if (prevProps.files.all_trash !== this.props.files.all_trash) {
            if (this.state.trash) {
                this.setState({ file_list: this.props.files.all_trash, breadcrumbs: [], file_details: {}, files_selected: [], selected_files: {} });
            }
        }

        if (prevProps.files.shared_files !== this.props.files.shared_files) {
            if (this.state.shared) {
                this.setState({ file_list: this.props.files.shared_files, breadcrumbs: [], file_details: {}, files_selected: [], selected_files: {} });
                $("#dismiss_share_info_modal").click();
            }
        }

        if (prevProps.files.received_files !== this.props.files.received_files) {
            if (this.state.received && !this.state.breadcrumbs.length) {
                this.setState({ file_list: this.props.files.received_files, file_details: {}, files_selected: [], selected_files: {} });
            }
        }

        if (prevProps.files.received_children !== this.props.files.received_children) {
            if (this.state.received && this.state.breadcrumbs.length) {
                this.setState({ file_list: this.props.files.received_children, file_details: {}, files_selected: [], selected_files: {} });
            }
        }

        if (prevProps.files.all_files_filtered !== this.props.files.all_files_filtered) {
            if (!this.state.shared && !this.state.received) {
                let parent = this.props.files.all_files.filter(item => item.filename === '/');
                let breadcrumbs = [parent[0]];
                this.setState({ file_list: this.props.files.all_files_filtered, breadcrumbs: breadcrumbs, file_details: {}, files_selected: [], selected_files: {}, });
            }
        }

        if (prevProps.files.shared_files_filtered !== this.props.files.shared_files_filtered) {
            if (this.state.shared) {
                this.setState({ file_list: this.props.files.shared_files_filtered, breadcrumbs: [], file_details: {}, files_selected: [], selected_files: {} });
                $("#dismiss_share_info_modal").click();
            }
        }
    }

    selectFiles = (data) => {

        let newState = { ...this.state.selected_files };

        if (newState[data.fid]) {
            delete newState[data.fid];
        } else {
            newState[data.fid] = data;
        }

        this.setState({
            selected_files: newState
        });

        let results = Object.values(newState);

        this.setState({ files_selected: results });

    }

    enterFolder = (data) => {

        // check for password
        let pass = this.checkPassword([data]);
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === data.password) {

                    if (!this.state.trash && !this.state.received) {
                        let file_list = this.props.files.all_files.filter(item => item.parent + '' === data.fid + '');

                        let breadcrumbs = [...this.state.breadcrumbs, data];

                        this.setState({ file_list: file_list, breadcrumbs: breadcrumbs, current_directory: data.fid, files_selected: [], selected_files: {} });
                    } else if (this.state.received) {
                        this.props.serverGetReceivedChildren(data, this.props.auth.user.token);
                        let breadcrumbs = [...this.state.breadcrumbs, data];
                        this.setState({ breadcrumbs: breadcrumbs, current_directory: data.fid, files_selected: [], selected_files: {} });
                    }

                } else {
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return
                }
            }
        } else {

            if (!this.state.trash && !this.state.received) {
                let file_list = this.props.files.all_files.filter(item => item.parent + '' === data.fid + '');

                let breadcrumbs = [...this.state.breadcrumbs, data];

                this.setState({ file_list: file_list, breadcrumbs: breadcrumbs, current_directory: data.fid, files_selected: [], selected_files: {} });
            } else if (this.state.received) {
                this.props.serverGetReceivedChildren(data, this.props.auth.user.token);
                let breadcrumbs = [...this.state.breadcrumbs, data];
                this.setState({ breadcrumbs: breadcrumbs, current_directory: data.fid, files_selected: [], selected_files: {} });
            }
        }
    }

    enterFolderAlt = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.files.all_files.filter(item => item.parent + '' === data.fid + '');
            this.setState({ file_list: file_list, current_directory: data.fid, files_selected: [], selected_files: {} });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    clickBreadCrumbs = (index) => {
        if (this.state.received) {
            let selected_breadcrumbs = this.state.breadcrumbs[index];
            this.props.serverGetReceivedChildren(selected_breadcrumbs, this.props.auth.user.token);
            let breadcrumbs = [...this.state.breadcrumbs, selected_breadcrumbs];
            breadcrumbs = breadcrumbs.slice(0, index + 1);
            this.setState({ breadcrumbs: breadcrumbs, current_directory: selected_breadcrumbs.fid, files_selected: [], selected_files: {} });

            // console.log(breadcrumbs, selected_breadcrumbs);
        } else {
            let breadcrumbs = [...this.state.breadcrumbs];
            let selected_breadcrumbs = this.state.breadcrumbs[index];
            let file_list = this.props.files.all_files.filter(item => item.parent + '' === selected_breadcrumbs.fid + '');
            breadcrumbs = breadcrumbs.slice(0, index + 1);
            return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs, current_directory: selected_breadcrumbs.fid, files_selected: [], selected_files: {} });
        }
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            file_details: {                   // object that we want to update
                ...prevState.file_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));

    }

    handleSearch = (data) => {
        this.setState(prevState => ({
            search_details: {                   // object that we want to update
                ...prevState.search_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));

    }

    uploadFiles = () => {
        // check entry
        if (!this.state.file_details.upload_files) {
            toast.error("Please select at least a single file.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let file_type_list = [...this.state.file_type_list];
        let all_file_types = [];
        file_type_list.forEach(element => {
            all_file_types.push(element.value);
        });

        let owner = this.props.auth.user.contact_id;
        let current_directory = this.state.current_directory;

        // Create an object of formData
        const formData = new FormData();

        if (this.state.file_details.upload_files) {

            for (let index = 0; index < this.state.file_details.upload_files.length; index++) {
                const element = this.state.file_details.upload_files[index];

                if (this.state.received) {
                    let file_name = element.name;
                    let file_size = (element.size / 1000000).toFixed(2);
                    let ext = file_name.split('.').pop();
                    ext = "." + ext;
                    owner = this.state.breadcrumbs[0].owner;
                    current_directory = this.state.breadcrumbs[0].fid;

                    if (this.state.breadcrumbs[0].upload_acceptable_file !== "" && !this.state.breadcrumbs[0].upload_acceptable_file.includes(ext)) {
                        toast.error("You are attempting to upload a file that is not allowed in this directory", {
                            position: 'top-center',
                            autoClose: 3000
                        });

                        return
                    }

                    if (this.state.breadcrumbs[0].upload_size_limit !== "" && (file_size > parseFloat(this.state.breadcrumbs[0].upload_size_limit))) {
                        toast.error("You can only upload files with size less than " + this.state.breadcrumbs[0].upload_size_limit + " MB", {
                            position: 'top-center',
                            autoClose: 3000
                        });

                        return
                    }

                }

                formData.append("uploaded_files[]", element);
            }

            formData.append("current_directory", current_directory);
            formData.append("owner", owner);

            this.props.serverUploadFiles(formData, this.props.auth.user.token)
        }
    }

    uploadUrl = () => {
        // check entry
        if (!this.state.file_details.upload_url) {
            toast.error("Please enter a valid url.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let owner = this.props.auth.user.contact_id;
        if (this.state.received) {
            owner = this.state.breadcrumbs[0].owner;
        }

        let data = {
            "url": this.state.file_details.upload_url,
            "current_directory": this.state.current_directory,
            "owner": owner
        }

        this.props.serverUploadUrl(data, this.props.auth.user.token)
    }

    createFile = (doc_type) => {
        // check entry
        if (!this.state.file_details.create_doc_file_name) {
            toast.error("Please enter a file name.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let owner = this.props.auth.user.contact_id;
        if (this.state.received) {
            owner = this.state.breadcrumbs[0].owner;
        }

        let data = {
            "file_name": this.state.file_details.create_doc_file_name,
            "current_directory": this.state.current_directory,
            "doc_type": doc_type,
            "owner": owner
        }

        this.props.serverCreateFile(data, this.props.auth.user.token)
    }

    createFolder = () => {
        // check entry
        if (!this.state.file_details.create_folder_name) {
            toast.error("Please enter folder name.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let owner = this.props.auth.user.contact_id;
        if (this.state.received) {
            owner = this.state.breadcrumbs[0].owner;
        }

        let data = {
            "folder_name": this.state.file_details.create_folder_name,
            "current_directory": this.state.current_directory,
            "owner": owner
        }

        this.props.serverCreateFolder(data, this.props.auth.user.token)
    }

    trashFiles = () => {
        if (window.confirm("Are you sure you want to move selected files to trash?") === true) {

            let selected_files = { ...this.state.selected_files };

            this.props.serverDeleteFiles(selected_files, this.props.auth.user.token);
        }
    }

    renameFile = () => {
        // check for password
        let pass = this.checkPassword();
        let state = this.checkPrivilege("rename");
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === this.state.files_selected[0].password) {
                    // check for privileges
                    if (state === 1) {
                        if (!this.state.file_details.rename_file_name) {
                            toast.error("Please enter new file/folder name.", {
                                position: 'top-center',
                                autoClose: 3000
                            });

                            return
                        }

                        let data = {
                            "file_name": this.state.file_details.rename_file_name,
                            "fid": this.state.files_selected[0].fid
                        }

                        this.props.serverRenameFile(data, this.props.auth.user.token)
                    } else {
                        toast.error("Sorry, you dont have permission to rename selected file", {
                            position: 'top-center',
                            autoClose: 3000
                        });
                    }
                } else {
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });
                }
            }
        } else {
            // check for privileges
            if (state === 1) {
                if (!this.state.file_details.rename_file_name) {
                    toast.error("Please enter new file/folder name.", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return
                }

                let data = {
                    "file_name": this.state.file_details.rename_file_name,
                    "fid": this.state.files_selected[0].fid
                }

                this.props.serverRenameFile(data, this.props.auth.user.token)
            } else {
                toast.error("Sorry, you dont have permission to rename selected file", {
                    position: 'top-center',
                    autoClose: 3000
                });
            }
        }
    }

    downloadFiles = async () => {

        // check for password
        let pass = this.checkPassword();
        let state = this.checkPrivilege("download");
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === this.state.files_selected[0].password) {
                    // check for privileges
                    if (state === 1) {
                        this.state.files_selected.forEach(element => {
                            if (element.type === "file") {
                                axios.get(`${types.SERVER_URL}static/file_manager/files/${element.fid}${element.filetype}`, {
                                    responseType: 'blob',
                                }).then(res => {
                                    fileDownload(res.data, `${element.filename}${element.filetype}`);
                                });
                            }
                        });
                    } else {
                        toast.error("Sorry, you dont have permission to download selected file", {
                            position: 'top-center',
                            autoClose: 3000
                        });
                    }
                } else {
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });
                }
            }
        } else {
            // check for privileges
            if (state === 1) {
                this.state.files_selected.forEach(element => {
                    if (element.type === "file") {
                        axios.get(`${types.SERVER_URL}static/file_manager/files/${element.fid}${element.filetype}`, {
                            responseType: 'blob',
                        }).then(res => {
                            fileDownload(res.data, `${element.filename}${element.filetype}`);
                        });
                    }
                });
            }
        }
    }

    setFile = (files_selected) => {

        // let files_selected = this.state.files_selected[0];

        let kind = "";
        let document_config = {};
        if (files_selected.filetype === '.docx') {
            kind = "word";
        } else if (files_selected.filetype === '.pptx') {
            kind = "slide";
        } else if (files_selected.filetype === '.xlsx' || files_selected.filetype === ".csv") {
            kind = "cell";
        }

        if (kind !== "") {
            document_config = {
                "document": {
                    "fileType": files_selected.filetype.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": true
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": files_selected.filename.replace(/&/g,""),
                    "url": `${types.SERVER_URL}static/file_manager/files/${files_selected.fid.toString()}${files_selected.filetype}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}fm/documentserver_callback?file=${files_selected.fid.toString()}${files_selected.filetype}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }
        }

        // check for password
        let pass = this.checkPassword([files_selected]);
        let state = this.checkPrivilege("preview");
        if (!pass) {
            // demand for password
            let password = prompt("Enter password");

            if (password != null) {
                if (password + "" === files_selected.password + "") {
                    // check for privileges
                    if (state === 0) {
                        $("#dismiss_view_file_modal").click();
                        toast.error("Sorry, you dont have permission to preview selected file", {
                            position: 'top-center',
                            autoClose: 3000
                        });

                        return
                    }
                } else {
                    $("#dismiss_view_file_modal").click();
                    toast.error("Wrong password", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return
                }
            } else {
                $("#dismiss_view_file_modal").click();
                return
            }
        } else {
            // check for privileges
            if (state === 0) {
                $("#dismiss_view_file_modal").click();
                toast.error("Sorry, you dont have permission to preview selected file", {
                    position: 'top-center',
                    autoClose: 3000
                });

                return
            }
        }

        this.setState({ document_config: document_config, files_selected: [files_selected], selected_files: { [files_selected.fid]: { files_selected } } });

        if (kind !== "") {
            // open in new tab for office documents
            window.open(encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(document_config)}`), files_selected.filename);
            this.unSelectAll();
            $("#dismiss_view_file_modal").click();
        }
    }

    fileType = (type) => {

        let image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        let video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        let audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        let compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        let word_type_list = ['.doc', '.dot', '.wbk', '.docx', '.docm', '.dotx', 'dotm', 'docb'];

        let excel_type_list = ['.xls', '.xlt', '.xlm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlsb', '.xla', '.xlam', '.xll', '.xlw', '.csv'];

        let powerpoint_type_list = ['.ppt', '.pot', '.pps', '.pptx', '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.sldx', '.sldm'];

        let access_type_list = ['.accdb', '.accde', '.accdt', '.accdr'];

        let doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];

        let all_file_types = image_type_list.concat(video_type_list).concat(audio_type_list).concat(word_type_list).concat(excel_type_list).concat(powerpoint_type_list).concat(access_type_list).concat(compressed_type_list).concat(doc_type_list);

        if (type === "all") {
            let file_type_list = [];
            all_file_types.forEach(element => {
                file_type_list.push({ "label": element, "value": element })
            });
            this.setState({ file_type_list: file_type_list });
        }

        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    copy = () => {
        let selected_files = this.state.files_selected;
        let current_directory = this.state.current_directory;

        this.setState({ copied_items: selected_files, copy: 1, move: 0, floating_directory: current_directory });

        toast.info("Navigate to the destination folder and click on 'Paste'", {
            position: 'bottom-right',
            autoClose: 3000
        });
    }

    move = () => {
        let selected_files = this.state.files_selected;
        let current_directory = this.state.current_directory;

        this.setState({ copied_items: selected_files, move: 1, copy: 0, floating_directory: current_directory });

        toast.info("Navigate to the destination folder and click on 'Paste'", {
            position: 'bottom-right',
            autoClose: 3000
        });
    }

    paste = () => {
        if (this.state.current_directory === this.state.floating_directory) {
            toast.error("You cannot paste into the same folder in which you copied from", {
                position: 'top-center',
                autoClose: 3000
            });
            return
        }

        let data = {
            "copied_items": this.state.copied_items,
            "new_directory": this.state.current_directory
        }

        if (this.state.copy) {
            this.props.serverCopyFiles(data, this.props.auth.user.token);
        } else if (this.state.move) {
            this.props.serverMoveFiles(data, this.props.auth.user.token);
        }

        this.setState({ copied_items: [], copy: 0, move: 0, floating_directory: null });
    }

    shareInApp = () => {

        let file_details = { ...this.state.file_details }

        if (!file_details.recipients) {
            toast.error("Please select your recipients", {
                position: 'top-center',
                autoClose: 3000
            });
            return
        }

        file_details['selected_files'] = this.state.files_selected;

        this.props.serverShareInApp(file_details, this.props.auth.user.token);
    }

    shareViaLink = () => {

        let file_details = { ...this.state.file_details }

        file_details['selected_files'] = this.state.files_selected;

        this.props.serverShareViaLink(file_details, this.props.auth.user.token);
    }

    reload = () => {

        this.props.serverGetAllFiles(this.props.auth.user.token);
        this.props.serverGetAllTrash(this.props.auth.user.token);
        this.props.serverGetSharedFiles(this.props.auth.user.token);
        if (this.state.received) {
            if (!this.state.breadcrumbs.length) {
                this.props.serverGetReceivedFiles(this.props.auth.user.token);
            } else {
                this.props.serverGetReceivedChildren(this.state.breadcrumbs[this.state.breadcrumbs.length - 1], this.props.auth.user.token);
            }
        }
        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        }
        if (!this.props.files.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        }
    }

    selectAll = () => {
        let newState = {};

        let file_list = [...this.state.file_list];

        file_list.forEach(element => {
            newState[element.fid] = element;

        });

        let results = Object.values(newState);

        this.setState({ selected_files: newState, files_selected: results });
    }

    unSelectAll = () => {
        this.setState({ selected_files: {}, files_selected: [] });
    }

    viewTrash = () => {
        if (!this.props.files.all_trash.length) {
            this.props.serverGetAllTrash(this.props.auth.user.token);
        } else {
            this.setState({ file_list: this.props.files.all_trash, file_details: {}, files_selected: [], selected_files: {} });
        }
        return this.setState({ trash: 1, shared: 0, received: 0, breadcrumbs: [] });
    }

    goHome = () => {

        let parent = this.props.files.all_files.filter(item => item.filename === '/');

        let file_list = [];
        let breadcrumbs = [];

        if (parent.length) {
            file_list = this.props.files.all_files.filter(item => item.parent + '' === parent[0].fid + '');
            breadcrumbs = [parent[0]];
        }

        this.setState({ file_list: file_list, breadcrumbs: breadcrumbs, file_tree: this.props.files.tree, current_directory: parent[0].fid, trash: 0, shared: 0, received: 0 });
    }

    deleteFiles = () => {
        if (window.confirm("Are you sure you want to permanently delete selected files?") === true) {
            this.props.serverDeleteTrash(this.state.files_selected, this.props.auth.user.token);
        }
    }

    emptyTrash = () => {
        if (window.confirm("Are you sure you want to empty your trash?") === true) {
            this.props.serverDeleteTrash(this.state.file_list, this.props.auth.user.token);
        }
    }

    restore = () => {
        if (window.confirm("Are you sure you want to restore selected files?") === true) {
            this.props.serverRestoreTrash(this.state.files_selected, this.props.auth.user.token);
        }
    }

    sharedFiles = () => {
        if (!this.props.files.all_trash.length) {
            this.props.serverGetSharedFiles(this.props.auth.user.token);
        } else {
            this.setState({ file_list: this.props.files.shared_files, file_details: {}, files_selected: [], selected_files: {} });
        }
        return this.setState({ shared: 1, received: 0, trash: 0, breadcrumbs: [] });
    }

    shareInfo = (data) => {
        this.setState({ file_details: data });
    }

    unshareFiles = () => {
        if (window.confirm("Are you sure you want to unshare this file? Recipients will no longer have access to it.") === true) {
            let data = {
                "share_id": this.state.file_details.share_id
            }
            this.props.serverUnshareFiles(data, this.props.auth.user.token);
        }
    }

    goToSource = (nodeIds) => {

        let file_list = this.props.files.all_files.filter(item => item.parent + '' === nodeIds + '');

        this.formBreadcrumbs(nodeIds);

        this.setState({ file_list: file_list, breadcrumbs: bread_output.reverse(), current_directory: nodeIds, files_selected: [], selected_files: {}, trash: 0, shared: 0, received: 0 });

        bread_output = [];
        $("#dismiss_share_info_modal").click();
    }

    updateShareRules = () => {
        this.props.serverUpdateShareRules(this.state.file_details, this.props.auth.user.token);
    }

    receivedFiles = () => {
        if (!this.props.files.all_trash.length) {
            this.props.serverGetReceivedFiles(this.props.auth.user.token);
        } else {
            this.setState({ file_list: this.props.files.received_files, file_details: {}, files_selected: [], selected_files: {} });
        }
        return this.setState({ shared: 0, received: 1, trash: 0, breadcrumbs: [] });
    }

    checkPrivilege = (privilege) => {
        if (this.state.received) {

            let files_selected = [...this.state.files_selected];

            switch (privilege) {
                case 'upload':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'upload'")) {
                            return 1
                        }
                    }
                    return 0;

                case 'new_folder':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'new_folder'")) {
                            return 1
                        }
                    }
                    return 0;

                case 'download':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'download'")) {
                            return 1
                        }
                    }

                    if (files_selected.length) {
                        if (files_selected[0].actions === "" || files_selected[0].actions.includes("'value': 'download'")) {
                            return 1
                        }
                    }

                    return 0;

                case 'rename':
                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'rename'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    if (files_selected.length) {
                        if (files_selected[0].actions === "" || files_selected[0].actions.includes("'value': 'rename'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    return 1;

                case 'preview':

                    if (this.state.breadcrumbs.length) {
                        if (this.state.breadcrumbs[0].actions === "" || this.state.breadcrumbs[0].actions.includes("'value': 'preview'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    if (files_selected.length) {
                        if (files_selected[0].actions === "" || files_selected[0].actions.includes("'value': 'preview'")) {
                            return 1
                        } else {
                            return 0;
                        }
                    }

                    return 1

                default:
                    return 0;
            }
        }
        return 1
    }

    checkPassword = (file) => {

        if (this.state.received) {

            let files_selected = [...this.state.files_selected];

            if (!files_selected.length && typeof (file) !== "undefined") {
                files_selected = file;
            }

            if (files_selected.length && !this.state.breadcrumbs.length) {
                if (files_selected[0].password !== "") {
                    return 0
                }
            }
        }

        return 1
    }

    clickTree = (event, nodeIds) => {

        let file_list = this.props.files.all_files.filter(item => item.parent + '' === nodeIds + '');

        this.formBreadcrumbs(nodeIds);

        this.setState({ file_list: file_list, breadcrumbs: bread_output.reverse(), current_directory: nodeIds, files_selected: [], selected_files: {}, trash: 0, shared: 0, received: 0 });

        bread_output = [];
    }

    formBreadcrumbs = (nodeIds) => {
        let file = this.props.files.all_files.filter(item => item.fid + '' === nodeIds + '');

        if (file.length) {
            bread_output.push(file[0]);

            this.formBreadcrumbs(file[0].parent);
        }
    }

    showSearch = () => {
        if (this.state.show_search) {
            this.setState({ show_search: 0 });
        } else {
            this.setState({ show_search: 1 });
        }
    }

    doSearch = () => {

        this.props.serverDoSearch(this.state.search_details, this.props.auth.user.token);

        this.setState({ show_search: 0 });
    }

    clearModalContents = () => {
        this.setState({ file_details: {} });
    }

    mainContextMenu = (data) => {

        this.setState({ files_selected: [data], selected_files: { [data.fid]: { data } } })

        window.document.addEventListener("contextmenu", function (event) {
            event.preventDefault();
            let contextElem = document.getElementById('my-context-area')
            // Box position & sizes
            var boxX = contextElem.offsetLeft;
            var boxY = contextElem.offsetTop;

            if (event.pageX > boxX && event.pageY > boxY) {
                var contextElement = document.getElementById("context-menu");
                if (event.pageY > window.screen.height) {
                    contextElement.style.top = event.pageY - window.screen.height + "px";
                } else {
                    contextElement.style.top = event.pageY + "px";
                }
                contextElement.style.left = event.pageX + "px";
                contextElement.classList.add("active");
            } else {

            }
        });
        window.addEventListener("click", function () {
            if (document.getElementById("context-menu")) {
                document.getElementById("context-menu").classList.remove("active");
            }
        });
    }

    copyLink = (link) => {
        navigator.clipboard.writeText(link);

        toast.info("Link copied successfully", {
            position: 'top-center',
            autoClose: 3000
        });
    }

    render() {
        return (
            <>
                <Main>
                    <Files
                        c_state={{ ...this.state }}
                        files={this.props.files}
                        auth={this.props.auth}
                        select_files={this.selectFiles}
                        enter_folder={this.enterFolder}
                        click_breadcrumbs={this.clickBreadCrumbs}
                        handleChange={this.handleChange}
                        handleSearch={this.handleSearch}
                        upload_files={this.uploadFiles}
                        upload_url={this.uploadUrl}
                        create_file={this.createFile}
                        create_folder={this.createFolder}
                        trash_files={this.trashFiles}
                        rename_file={this.renameFile}
                        download_files={this.downloadFiles}
                        set_file={this.setFile}
                        file_type={this.fileType}
                        copy={this.copy}
                        move={this.move}
                        paste={this.paste}
                        share_in_app={this.shareInApp}
                        share_via_link={this.shareViaLink}
                        reload={this.reload}
                        select_all={this.selectAll}
                        unselect_all={this.unSelectAll}
                        view_trash={this.viewTrash}
                        go_home={this.goHome}
                        delete_files={this.deleteFiles}
                        empty_trash={this.emptyTrash}
                        restore={this.restore}
                        shared_files={this.sharedFiles}
                        share_info={this.shareInfo}
                        unshare_files={this.unshareFiles}
                        go_to_source={this.goToSource}
                        update_share_rules={this.updateShareRules}
                        received_files={this.receivedFiles}
                        check_privilege={this.checkPrivilege}
                        click_tree={this.clickTree}
                        show_search={this.showSearch}
                        do_search={this.doSearch}
                        clear_modal_contents={this.clearModalContents}
                        main_context_menu={this.mainContextMenu}
                        copy_link={this.copyLink}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        files: { ...state.files },
        contacts: { ...state.contacts }
    }
}

export default connect(mapStateToProps, {
    // checkUser,
    serverLoad,
    serverGetContacts,
    serverGetAllFiles,
    serverUploadFiles,
    serverUploadUrl,
    serverCreateFile,
    serverCreateFolder,
    serverDeleteFiles,
    serverRenameFile,
    serverCopyFiles,
    serverMoveFiles,
    serverShareInApp,
    serverShareViaLink,
    serverGetAllTrash,
    serverDeleteTrash,
    serverRestoreTrash,
    serverGetSharedFiles,
    serverUnshareFiles,
    serverUpdateShareRules,
    serverGetReceivedFiles,
    serverGetTags,
    serverDoSearch,
    serverGetReceivedChildren
})(FilesContainer)


