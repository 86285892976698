import React, { Component } from 'react'
import Login from './Login'
import { connect } from 'react-redux'
import { serverLogin, serverLoad, checkUser, serverGoogleLogin } from './../../actions/AuthAction'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { getTrans as t } from '../../translations';
// refresh token
import { refreshTokenSetup } from '../../refreshToken';
import { gapi } from 'gapi-script';

class LoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }

        this.doLogin = this.doLogin.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }

    onSuccess = (res) => {
        // console.log('Login Success: currentUser:', res.profileObj);
        this.props.serverGoogleLogin({ email: res.profileObj.email, googleId: res.profileObj.googleId })
        // alert(
        //     `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
        // );
        refreshTokenSetup(res);
    };

    onFailure = (res) => {
        console.log('Login failed: res:', res);
        if (res.error !== "idpiframe_initialization_failed") {
            toast.error(t('Failed to login', this.props.auth.language), {
                position: 'top-center',
                autoClose: 3000
            });
        }
    };

    componentDidMount() {

        // check from url
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const shareLink = urlParams.get('q');
        if (shareLink) {
            localStorage.setItem("token", shareLink);
            this.props.history.push("/files");
        }

        if (this.props.auth.user && this.props.auth.user.token && this.props.auth.user.token.trim().length) {
            this.props.history.push("/files");
        }

        gapi.load('client:auth2', null);

        // gapi.load('client:auth2', gapi.client.init({
        //     clientId: clientId,
        //     scope: ""
        // }))
    }

    componentDidUpdate(prevProps, prevState) { // this is called anytime there is changes in the props/state

        if (prevProps.auth.user !== this.props.auth.user) {
            if (this.props.auth.user !== null) {
                this.props.history.push("/files");
            }
        }

        if (this.props.auth.success === "true") {
            this.props.history.push("/files");
        } else if ((typeof this.props.auth.msg === 'string') && this.props.auth.msg.trim() > 0 && this.props.auth.success === "false") {
            alert(this.props.auth.msg);
        }
    }

    doLogin = () => {
        let obj = {
            password: this.state.password,
            email: this.state.email
        }

        this.props.serverLoad("true");

        this.props.serverLogin(obj);
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <>
                <Login
                    {...this.state}
                    handleChange={this.handleChange}
                    login_func={this.doLogin}
                    isLoading={this.props.auth.isLoading}
                    onSuccess={this.onSuccess}
                    onFailure={this.onFailure}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { serverLogin, serverLoad, checkUser, serverGoogleLogin })(LoginContainer)
