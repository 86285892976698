import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


toast.configure();

let defaultState = {
    isLoading: "false",
    contacts: [],
    companies: [],
    groups: []
}

const Contacts = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.CONTACTS_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.ALL_CONTACTS:

            newState.contacts = payload.data;

            return newState;

        case types.ALL_COMPANIES:

            newState.companies = payload.data;

            return newState;

        case types.ALL_GROUPS:

            newState.groups = payload.data;

            return newState;

        case types.CONTACTS_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.COMPANY_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.CONTACTS_SUCCESS:

            if (payload.msg === "Contact added successfully") {
                // eslint-disable-next-line array-callback-return
                newState.contacts = [...newState.contacts, { ...payload.data }]

                newState.contacts.sort((a, b) => (a.first_name > b.first_name) ? 1 : -1)
            }

            if (payload.msg === "Contacts uploaded successfully") {
                newState.contacts = payload.data;
            }

            if (payload.msg === "Contact deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.contacts = newState.contacts.filter((data, index) => { // loop through state.todo
                    if (data.contact_id !== payload.data.contact_id) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Contact updated successfully") {
                newState.contacts = newState.contacts.map((data, index) => { // loop through state.todo
                    if (data.contact_id === payload.data.contact_id) { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                        // console.log(data);
                    }
                    return data
                });

                newState.contacts.sort((a, b) => (a.first_name > b.first_name) ? 1 : -1)
            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.COMPANY_SUCCESS:

            if (payload.msg === "Company added successfully") {
                // eslint-disable-next-line array-callback-return
                newState.companies = [...newState.companies, { ...payload.data }]

                newState.companies.sort((a, b) => (a.name > b.name) ? 1 : -1)
            }

            if (payload.msg === "Company deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.companies = newState.companies.filter((data, index) => { // loop through state.todo
                    if (data.company_id !== payload.data.company_id) { // check what data to update using key
                        return data
                    }
                })
            }

            if (payload.msg === "Company updated successfully") {
                newState.companies = newState.companies.map((data, index) => { // loop through state.todo
                    if (data.company_id === payload.data.company_id) { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                        // console.log(data);
                    }
                    return data
                });

                newState.companies.sort((a, b) => (a.name > b.name) ? 1 : -1)
            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.GROUP_SUCCESS:

            if (payload.msg === "Group created successfully") {
                // eslint-disable-next-line array-callback-return
                newState.groups = [...newState.groups, { ...payload.data }]

                newState.groups.sort((a, b) => (a.group_name > b.group_name) ? 1 : -1)
            }

            if (payload.msg === "Group updated successfully") {
                newState.groups = newState.groups.map((data, index) => { // loop through state.todo
                    if (data.group_id === payload.data.group_id) { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                        // console.log(data);
                    }
                    return data
                });

                newState.companies.sort((a, b) => (a.group_name > b.group_name) ? 1 : -1)
            }

            if (payload.msg === "Group deleted successfully") {
                // eslint-disable-next-line array-callback-return
                newState.groups = newState.groups.filter((data, index) => { // loop through state.todo
                    if (data.group_id !== payload.data.group_id) { // check what data to update using key
                        return data
                    }
                })
            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        default:

            return newState;
    }
}

export default Contacts