import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    all_files: [],
    tree: {
        id: 'root',
        name: 'Home',
        children: []
    },
    file_link: '',
    all_trash: [],
    shared_files: [],
    received_files: [],
    received_children: [],
    tags: [],
    all_files_filtered: [],
    all_trash_filtered: [],
    shared_files_filtered: [],
    received_files_filtered: [],
    link_files: [],
    link_children: []
}

const Files = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.FILES_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.ALL_FILES:

            newState.all_files = payload.data.files;

            let tree = newState.tree;
            let tree_root = payload.data.tree_root;
            tree_root['filename'] = "Home";

            tree = tree_root;
            tree['children'] = payload.data.tree;

            newState.tree = tree;

            return newState;

        case types.FILES_SUCCESS:

            if (payload.msg === "Files uploaded successfully") {
                newState.all_files = [...newState.all_files, ...payload.data.all_files];
                newState.received_children = payload.data.received_children;
            }

            if (payload.msg === "File uploaded successfully" || payload.msg === "File created successfully") {
                newState.all_files = [...newState.all_files, payload.data];
                newState.received_children = [...newState.received_children, payload.data];
                newState.link_children = [...newState.link_children, payload.data];
            }

            if (payload.msg === "Folder created successfully") {
                newState.all_files = [...newState.all_files, payload.data.all_files];
                newState.received_children = payload.data.received_children;

                let tree = newState.tree;
                let tree_root = payload.data.tree_root;
                tree_root['filename'] = "Home";

                tree = tree_root;
                tree['children'] = payload.data.tree;

                newState.tree = tree;
            }

            if (payload.msg === "Files trashed successfully" || payload.msg === "Files restored successfully") {
                newState.all_files = payload.data.files;
                newState.all_trash = payload.data.trash;
            }

            if (payload.msg === "File renamed successfully") {
                newState.all_files = newState.all_files.map((data, index) => { // loop through state.todo
                    if (data.fid + "" === payload.data.fid + "") { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                    }
                    return data
                });

                newState.received_files = newState.received_files.map((data, index) => { // loop through state.todo
                    if (data.fid + "" === payload.data.fid + "") { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                    }
                    // console.log(data);
                    return data
                });

                newState.received_children = newState.received_children.map((data, index) => { // loop through state.todo
                    if (data.fid + "" === payload.data.fid + "") { // check what data to update using key
                        data = { ...data, ...payload.data } // replace resulting data with new payload
                    }
                    return data
                });
            }

            if (payload.msg === "Files copied successfully" || payload.msg === "Files moved successfully" ||
                payload.msg === "Files shared successfully") {
                newState.all_files = payload.data.files;
                newState.tree = payload.data.tree
            }

            if (payload.msg === "Link generated successfully and has been copied to clipboard.") {
                newState.all_files = payload.data.files;
                newState.tree = payload.data.tree
                newState.file_link = payload.data.link
            }

            if (payload.msg === "Files deleted successfully") {
                newState.all_trash = payload.data;
            }

            if (payload.msg === "Files have been unshared successfully" || payload.msg === "Rules updated successfully") {
                newState.shared_files = payload.data;
            }

            if (payload.msg === "File(s) trashed successfully") {
                // eslint-disable-next-line array-callback-return
                newState.link_files = newState.link_files.filter((data, index) => { // loop through state.todo
                    if (!payload.data.includes(data.fid + "")) { // check what data to update using key
                        return data;
                    }
                });
                // eslint-disable-next-line array-callback-return
                newState.link_children = newState.link_children.filter((data, index) => { // loop through state.todo
                    if (!payload.data.includes(data.fid + "")) { // check what data to update using key
                        return data;
                    }
                });
            }

            if (payload.msg === "File has been renamed successfully") {
                newState.link_files = newState.link_files.map((data, index) => { // loop through state.todo
                    if (data.fid + "" === payload.data.link_files.fid + "") { // check what data to update using key
                        data = { ...data, ...payload.data.link_files } // replace resulting data with new payload
                    }
                    return data;
                });

                newState.link_children = newState.link_children.map((data, index) => { // loop through state.todo
                    if (data.fid + "" === payload.data.link_children.fid + "") { // check what data to update using key
                        data = { ...data, ...payload.data.link_children } // replace resulting data with new payload
                    }
                    return data;
                });
            }

            if (payload.msg === "File(s) uploaded successfully" || payload.msg === "File saved successfully") {
                newState.link_children = payload.data;
            }

            if (payload.msg === "Folder has been created successfully") {
                newState.link_children = payload.data;
            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.ALL_TRASH:

            newState.all_trash = payload.data.files;

            return newState;

        case types.ALL_SHARED:

            newState.shared_files = payload.data;

            return newState;

        case types.ALL_RECEIVED:

            if (payload.msg === "Received files loaded successfully") {
                newState.received_files = payload.data;
            }

            if (payload.msg === "Received children loaded successfully") {
                newState.received_children = payload.data;
            }

            return newState;

        case types.TAGS:

            newState.tags = payload.data;

            return newState;

        case types.SEARCHED_FILES:

            newState.all_files_filtered = payload.data.all_files;
            newState.shared_files_filtered = payload.data.shared_files;
            newState.received_files_filtered = payload.data.received_files;
            newState.all_trash_filtered = payload.data.trash;

            return newState;

        case types.LINK_FILES:            

            if (payload.msg === "Received children loaded successfully" || payload.msg === "Link children loaded successfully") {
                newState.link_children = payload.data;
            }else{
                newState.link_files = payload.data;
            }

            return newState;

        case types.FILES_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        default:
            return newState;
    }
}

export default Files