import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    email: "",
    password: "",
    msg: "",
    user: null,
    success: "false",
    isLoading: "false",
    clear_data: "false",
    rand: "",
    language: "en"
}

const Auth = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.LOADING:

            newState.isLoading = payload;

            return newState;

        case types.CLEAR_DATA:

            newState.clear_data = payload;

            return newState;

        case types.LOGIN_SUCCESS:

            newState.msg = payload.msg
            newState.success = payload.success
            newState.user = payload.data
            newState.isLoading = "false";

            localStorage.setItem("token", payload.data.token);
            // localStorage.setItem("first", "0");

            if (payload.msg === "Account switched successfully") {
                window.location.reload();
            }

            return newState;

        case types.LOGIN_VERIFY:

            newState.rand = payload.data;

            if (payload.msg === "Password changed successfully") {
                toast.success(payload.msg, {
                    position: 'top-center',
                    autoClose: 3000
                });
            }

            return newState;

        case types.REASSIGN_PRIVILEGES:

            if (payload.data.length) {
                let user = { ...newState.user };
                user['privileges'] = payload.data;
                newState.user = user;

                // localStorage.setItem("user", JSON.stringify(newState.user));
            }

            return newState;

        case types.LOGIN_FAIL:

            newState.msg = payload.msg
            newState.success = payload.success

            newState.isLoading = "false";

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 3000
            });

            return newState;

        case types.DO_LOGOUT:

            newState.msg = ""
            newState.success = "false"
            newState.user = null
            newState.isLoading = "false";

            localStorage.clear();

            window.location.href = "/";

            return newState;

        case types.CHANGE_LANGUAGE:

            newState.language = payload;

            return newState;

        default:
            return newState;
    }
}

export default Auth