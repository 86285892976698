import {combineReducers} from 'redux'
import authReducer from './AuthReducer'
import contactsReducer from './ContactsReducer'
import filesReducer from './FilesReducer'
import privilegesReducer from './PrivilegesReducer'

const Reducers = combineReducers({
    auth: authReducer,
    contacts: contactsReducer,
    files: filesReducer,
    privileges: privilegesReducer,
})

export default  Reducers