import React, { Component } from 'react'

import Stats from './Stats';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
import { serverGetAllFiles } from '../../actions/FilesAction';

class StatsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image_count: 0,
            audio_count: 0,
            video_count: 0,
            document_count: 0,
            zip_count: 0,
            other_files_count: 0,
            folder_count: 0,
            all_files: []
        }
    }

    componentDidMount() {

        let token = localStorage.getItem("token");
        if (!token) {
            window.location.href = '/';
        }

        if (!this.props.files.all_files.length) {
            this.props.serverGetAllFiles(token);
        } else {

            let all_files = this.props.files.all_files;

            let image_count = 0;
            let audio_count = 0;
            let video_count = 0;
            let document_count = 0;
            let zip_count = 0;
            let other_files_count = 0;
            let folder_count = 0;

            all_files.forEach(element => {
                if (this.fileType(element.filetype) === "image") {
                    image_count = image_count + 1;
                }else if (this.fileType(element.filetype) === "audio") {
                    audio_count = audio_count + 1;
                }else if (this.fileType(element.filetype) === "video") {
                    video_count = video_count + 1;
                }else if (this.fileType(element.filetype) === "docs" || this.fileType(element.filetype) === "office") {
                    document_count = document_count + 1;
                }else if (this.fileType(element.filetype) === "zip") {
                    zip_count = zip_count + 1;
                }else if (element.type === "file") {
                    other_files_count = other_files_count + 1;
                }

                if (element.type === "folder" && element.parent !== "#") {
                    folder_count = folder_count + 1;
                }
            });

            this.setState({
                all_files: all_files, image_count: image_count, audio_count: audio_count, folder_count: folder_count,
                video_count: video_count, document_count: document_count, zip_count: zip_count, other_files_count: other_files_count
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.files.all_files !== this.props.files.all_files) {

            let all_files = this.props.files.all_files;

            let image_count = 0;
            let audio_count = 0;
            let video_count = 0;
            let document_count = 0;
            let zip_count = 0;
            let other_files_count = 0;
            let folder_count = 0;

            all_files.forEach(element => {
                if (this.fileType(element.filetype) === "image") {
                    image_count = image_count + 1;
                }else if (this.fileType(element.filetype) === "audio") {
                    audio_count = audio_count + 1;
                }else if (this.fileType(element.filetype) === "video") {
                    video_count = video_count + 1;
                }else if (this.fileType(element.filetype) === "docs" || this.fileType(element.filetype) === "office") {
                    document_count = document_count + 1;
                }else if (this.fileType(element.filetype) === "zip") {
                    zip_count = zip_count + 1;
                }else if (element.type === "file") {
                    other_files_count = other_files_count + 1;
                }

                if (element.type === "folder" && element.parent !== "#") {
                    folder_count = folder_count + 1;
                }
            });

            this.setState({
                all_files: all_files, image_count: image_count, audio_count: audio_count, folder_count: folder_count,
                video_count: video_count, document_count: document_count, zip_count: zip_count, other_files_count: other_files_count
            });
        }

    }

    fileType = (type) => {

        let image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        let video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        let audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        let compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        let word_type_list = ['.doc', '.dot', '.wbk', '.docx', '.docm', '.dotx', 'dotm', 'docb'];

        let excel_type_list = ['.xls', '.xlt', '.xlm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlsb', '.xla', '.xlam', '.xll', '.xlw', '.csv'];

        let powerpoint_type_list = ['.ppt', '.pot', '.pps', '.pptx', '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.sldx', '.sldm'];

        let access_type_list = ['.accdb', '.accde', '.accdt', '.accdr'];

        let doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];

        let all_file_types = image_type_list.concat(video_type_list).concat(audio_type_list).concat(word_type_list).concat(excel_type_list).concat(powerpoint_type_list).concat(access_type_list).concat(compressed_type_list).concat(doc_type_list);

        if (type === "all") {
            let file_type_list = [];
            all_file_types.forEach(element => {
                file_type_list.push({ "label": element, "value": element })
            });
            this.setState({ file_type_list: file_type_list });
        }

        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else if (word_type_list.includes(type) || excel_type_list.includes(type) || powerpoint_type_list.includes(type) ||
            access_type_list.includes(type)) {

            return 'office';

        } else {

            return '';
        }
    }

    render() {
        return (
            <>
                <Main>
                    <Stats
                        c_state={{ ...this.state }}
                        auth={{ ...this.props.auth }}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        files: { ...state.files }
    }
}

export default connect(mapStateToProps, {
    serverGetAllFiles
})(StatsContainer)