import React from 'react';
import { Link } from "react-router-dom";
import types from '../../types';
// import Select from 'react-select'
import { getTrans as t } from '../../translations';

const ShareLink = (props) => {

    return (
        <div className="wrapper">
            {/* <!-- Navbar --> */}
            <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
                <div className="container">
                    <Link to="#" className="navbar-brand">
                        {/* <img src="static/assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: 0.8}} /> */}
                        <span className="brand-text font-weight-light"> File Manager</span>
                    </Link>

                    {/* <!-- Right navbar links --> */}
                    <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                        {/* <!-- Messages Dropdown Menu --> */}
                        <li className="nav-item dropdown">
                            <Link to="#" className="nav-link" data-toggle="dropdown">
                                <i className="fa fa-globe"></i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                <Link to="#" onClick={() => props.changeLanguage('en')} className="dropdown-item"> English - en </Link>
                                <div className="dropdown-divider"></div>
                                <Link to="#" onClick={() => props.changeLanguage('fr')} className="dropdown-item"> French - fr </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* <!-- /.navbar --> */}
            <div className="content-wrapper">
                {
                    props.c_state.valid_link ?
                        <>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12" style={{ marginTop: 10 }}>
                                        <div className="card">
                                            <div className="card-body" style={{ padding: 0, fontSize: 14 }}>
                                                <ul className="list-group list-group-horizontal-xl">
                                                    <li className="list-group-item dropdown" style={{ padding: 8 }}>
                                                        {
                                                            !props.c_state.files_selected.length && props.c_state.breadcrumbs.length && props.check_privilege("upload") ?
                                                                <>
                                                                    <Link to="#" id="dropdownSubMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="card-link"><i className='fa fa-plus'></i> {t('File', props.auth.language)}</Link>
                                                                    <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                                                                        <li><Link to="#" data-toggle="modal" data-target="#upload_file" className="dropdown-item">{t('My Computer', props.auth.language)} </Link></li>
                                                                        <li><Link to="#" data-toggle="modal" data-target="#upload_url" className="dropdown-item">{t('URL', props.auth.language)}</Link></li>

                                                                        <li className="dropdown-divider"></li>
                                                                        <li><Link to="#" data-toggle="modal" data-target="#create_word" className="dropdown-item"><img src="static/assets/dist/img/word.png" height="20" alt="word_logo" /> {t('Word Document', props.auth.language)}</Link></li>
                                                                        <li><Link to="#" data-toggle="modal" data-target="#create_powerpoint" className="dropdown-item"><img src="static/assets/dist/img/powerpoint.png" height="20" alt="powerpoint_logo" /> {t('Powerpoint', props.auth.language)}</Link></li>
                                                                        <li><Link to="#" data-toggle="modal" data-target="#create_excel" className="dropdown-item"><img src="static/assets/dist/img/excel.png" height="20" alt="excel_logo" /> {t('Sreadsheet', props.auth.language)}</Link></li>
                                                                    </ul>
                                                                </>
                                                                :
                                                                <span style={{ color: '#B2BEB5' }}><i className='fa fa-plus'></i> {t('File', props.auth.language)}</span>
                                                        }
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        {
                                                            !props.c_state.files_selected.length && props.c_state.breadcrumbs.length && props.check_privilege("new_folder") ?
                                                                <Link to="#" data-toggle="modal" data-target="#new_folder" className="card-link"><i className='fa fa-plus'></i> {t('Folder', props.auth.language)}</Link>
                                                                :
                                                                <span style={{ color: '#B2BEB5' }}><i className='fa fa-plus'></i> {t('Folder', props.auth.language)}</span>
                                                        }
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        <Link to="#" onClick={props.reload} className="card-link"><i className='fa fa-sync'></i> {t('Refresh', props.auth.language)}</Link>
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        <Link to="#" onClick={props.select_all} className="card-link"><i className='fa fa-check-square'></i> {t('Select All', props.auth.language)}</Link>
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        <Link to="#" onClick={props.unselect_all} className="card-link"><i className='fa fa-minus-square'></i> {t('Unselect All', props.auth.language)}</Link>
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        {
                                                            props.c_state.files_selected.length === 1 && props.c_state.files_selected[0].type === "file" ?
                                                                <Link to="#" onClick={props.download_files} className="card-link"><i className='fa fa-download'></i> {t('Download', props.auth.language)}</Link>
                                                                :
                                                                <span style={{ color: '#B2BEB5' }}><i className='fa fa-download'></i> {t('Download', props.auth.language)}</span>
                                                        }
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        {
                                                            props.c_state.files_selected.length ?
                                                                <Link to="#" onClick={props.trash_files} className="card-link"><i className='fa fa-trash'></i> {t('Delete', props.auth.language)}</Link>
                                                                :
                                                                <span style={{ color: '#B2BEB5' }}><i className='fa fa-trash'></i> {t('Delete', props.auth.language)}</span>
                                                        }
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        {
                                                            props.c_state.files_selected.length === 1 ?
                                                                <Link to="#" data-toggle="modal" data-target="#rename_file" className="card-link"><i className='fa fa-file'></i> {t('Rename', props.auth.language)}</Link>
                                                                :
                                                                <span style={{ color: '#B2BEB5' }}><i className='fa fa-file'></i> {t('Rename', props.auth.language)}</span>
                                                        }
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        {
                                                            props.c_state.files_selected.length === 1 && props.c_state.files_selected[0].type === 'file' ?
                                                                <Link to="#" onClick={() => props.set_file(props.c_state.files_selected[0])} data-toggle="modal" data-target="#view_file" className="card-link"><i className='fa fa-eye'></i> {t('View', props.auth.language)}</Link>
                                                                :
                                                                <span style={{ color: '#B2BEB5' }}><i className='fa fa-eye'></i> {t('View', props.auth.language)}</span>
                                                        }
                                                    </li>
                                                    <li className="list-group-item" style={{ padding: 8 }}>
                                                        {
                                                            props.c_state.files_selected.length === 1 ?
                                                                <Link to="#" data-toggle="modal" data-target="#file_note" className="card-link"><i className='fa fa-sticky-note'></i> {t('File Note', props.auth.language)}</Link>
                                                                :
                                                                <span style={{ color: '#B2BEB5' }}><i className='fa fa-sticky-note'></i> {t('File Note', props.auth.language)}</span>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- /.row --> */}
                            </div>
                            <div className="content">
                                <div className="row mb-2">
                                    <div className="col-lg-1">
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="card">
                                            <div className="card-body">
                                                {
                                                    props.files.isLoading === "true" ?
                                                        <div align="center">
                                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t("We are loading your files. Please wait...", props.auth.language)}</p></span>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="col-sm-12 row mt-2">
                                                                <ol className="breadcrumb float-sm-left">

                                                                    <li className="breadcrumb-item"><Link to="#" onClick={props.go_home}>{t('Home', props.auth.language)}</Link></li>
                                                                    {
                                                                        props.c_state.breadcrumbs.map((data, index) => {
                                                                            return (
                                                                                <li key={index} className="breadcrumb-item"><Link to="#" onClick={() => props.click_breadcrumbs(index)}>{data.filename === '/' ? t('Home', props.auth.language) : data.filename}</Link></li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ol>
                                                            </div>
                                                            <div className="row">
                                                                <div className="table-responsive" style={{ padding: 20, paddingTop: 0, height: 300, overflowY: 'scroll' }}>
                                                                    <table className="table table-hover text-nowrap">
                                                                        <thead>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>{t('Name', props.auth.language)}</th>
                                                                                <th>{t('Size', props.auth.language)}</th>
                                                                                <th>{t('Date Received', props.auth.language)}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                props.c_state.file_list.map((data, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td><input className="" type="checkbox" checked={props.c_state.selected_files[data.fid] ? true : false} onChange={() => props.select_files(data)} /></td>
                                                                                            {
                                                                                                data.type === "file" ?
                                                                                                    <>
                                                                                                        <td>&nbsp;<Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_file" onClick={() => props.set_file(data)}><i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i>{data.filename.substring(0, 45)}{data.filetype}</Link></td>
                                                                                                        <td><Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_file" onClick={() => props.set_file(data)}>{data.filesize} {data.filesize ? 'MB' : ''}</Link></td>
                                                                                                        <td><Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_file" onClick={() => props.set_file(data)}>{data.updated_at}</Link></td>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <td>&nbsp;<Link to="#" style={{ color: 'black' }} onClick={() => props.enter_folder(data)}><i style={{ color: 'red' }} className="fa fa-folder"> &nbsp;</i>{data.filename.substring(0, 45)}{data.filetype}</Link></td>
                                                                                                        <td><Link to="#" style={{ color: 'black' }} onClick={() => props.enter_folder(data)}>{data.filesize} {data.filesize ? 'MB' : ''}</Link></td>
                                                                                                        <td><Link to="#" style={{ color: 'black' }} onClick={() => props.enter_folder(data)}>{data.updated_at}</Link></td>
                                                                                                    </>
                                                                                            }

                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <section className="content">
                            <br /><br />
                            <div className="error-page">
                                <h2 className="headline text-danger">401</h2>

                                <div className="error-content">
                                    <h3><i className="fas fa-exclamation-triangle text-danger"></i> {t("Oops! Something went wrong.", props.auth.language)}</h3>

                                    <p>
                                    {t('The page you are trying to access appears to be invalid. Please contact your administrator or request for a new link.', props.auth.language)}
                                    </p>
                                </div>
                            </div>
                        </section>
                }

                {/* Modals */}

                {/* Modals */}

                <div className="modal fade" id="upload_file">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Upload File', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t('Select File', props.auth.language)}</span>
                                        <input className="form-control" type="file" multiple name="files[]" onChange={(event) => props.handleChange({ "field": "upload_files", "value": event.target.files })} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_upload_files_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.upload_files}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="upload_url">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Upload File (URL)', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t("Enter URL location of file to upload", props.auth.language)}</span>
                                        <input className="form-control" type="text" name="url" placeholder="http://" onChange={(event) => props.handleChange({ "field": "upload_url", "value": event.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_upload_url_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.upload_url}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="create_word">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Create Word Document', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t('Enter File Name', props.auth.language)}</span>
                                        <input className="form-control" type="text" placeholder="File Name" onChange={(event) => props.handleChange({ "field": "create_doc_file_name", "value": event.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_word_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={() => props.create_file("Word")}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="create_excel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Create Excel Document', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t('Enter File Name', props.auth.language)}</span>
                                        <input className="form-control" type="text" placeholder="File Name" onChange={(event) => props.handleChange({ "field": "create_doc_file_name", "value": event.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_excel_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={() => props.create_file("Excel")}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="create_powerpoint">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Create Powerpoint Document', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t('Enter File Name', props.auth.language)}</span>
                                        <input className="form-control" type="text" placeholder="File Name" onChange={(event) => props.handleChange({ "field": "create_doc_file_name", "value": event.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_powerpoint_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={() => props.create_file("Powerpoint")}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="new_folder">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Create New Folder', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t('Emter Folder Name', props.auth.language)}</span>
                                        <input className="form-control" type="text" placeholder="Folder Name" onChange={(event) => props.handleChange({ "field": "create_folder_name", "value": event.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_folder_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.create_folder}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="rename_file">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('Rename File/Folder', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t('Enter File/Folder Name', props.auth.language)}</span>
                                        <input className="form-control" type="text" placeholder={props.c_state.files_selected.length ? props.c_state.files_selected[0].filename : ''} onChange={(event) => props.handleChange({ "field": "rename_file_name", "value": event.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_rename_file_modal">{t('Close', props.auth.language)}</button>
                                <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.rename_file}>{t('Save changes', props.auth.language)}</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="file_note">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{t('File Note', props.auth.language)}</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <div className="form-group">
                                        <p>{props.c_state.files_selected.length ? props.c_state.files_selected[0].file_note ? props.c_state.files_selected[0].file_note : null : null}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_file_note_modal">{t('Close', props.auth.language)}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    props.c_state.files_selected.length && !props.c_state.trash ?
                        props.c_state.files_selected[0].type === "file" ?
                            <div className="modal fade" id="view_file" data-backdrop="static" tabIndex="-1" role="dialog">
                                <div className="modal-dialog modal-xl">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">{t('View File', props.auth.language)}</h4>
                                            <button type="button" onClick={props.unselect_all} className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                props.c_state.files_selected.length ?
                                                    props.c_state.files_selected[0].filetype === ".docx" || props.c_state.files_selected[0].filetype === ".pptx" || props.c_state.files_selected[0].filetype === ".xlsx" || props.c_state.files_selected[0].filetype === ".csv" ?
                                                        props.c_state.document_config ?
                                                            <div className="embed-responsive embed-responsive-16by9">
                                                                <iframe
                                                                    className="embed-responsive-item"
                                                                    title="doc_viewer"
                                                                    src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.c_state.document_config)}`)}
                                                                    allowFullScreen>
                                                                </iframe>
                                                            </div>
                                                            : null

                                                        : props.file_type(props.c_state.files_selected[0].filetype) === "audio" ?
                                                            <audio controls preload='auto' autoPlay width='100%' height='100%'>
                                                                <source src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`} type='audio/ogg; audio/mpeg' />
                                                            </audio>

                                                            : props.file_type(props.c_state.files_selected[0].filetype) === "image" ?
                                                                <img src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`} alt="attachment-img" />

                                                                : props.file_type(props.c_state.files_selected[0].filetype) === "video" ?
                                                                    <video controls preload='auto' width='100%' autoPlay height='100%'>
                                                                        <source src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`} type='video/mp4; video/webm; video/x-matroska' />
                                                                    </video>

                                                                    : props.c_state.files_selected[0].filetype === ".pdf" ?
                                                                        <div className="embed-responsive embed-responsive-16by9">
                                                                            <iframe
                                                                                className="embed-responsive-item"
                                                                                title="pdf_viewer"
                                                                                src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`}
                                                                                allowFullScreen>
                                                                            </iframe>
                                                                        </div>

                                                                        : <p align="center">{t('This file cannot be previewed', props.auth.language)}. <Link to="#" onClick={props.download_files}>{t('Click here to download', props.auth.language)}.</Link></p>
                                                    : null
                                            }
                                        </div>
                                        <div className="modal-footer justify-content-between">
                                            <button type="button" onClick={props.unselect_all} className="btn btn-default" data-dismiss="modal" id="dismiss_view_file_modal">{t('Close', props.auth.language)}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        : null
                }


                {/* End Modals */}
            </div>
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block">
                    <b>Version</b> 1.0.0
                </div>
                {/* <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights
			reserved. */}
                <strong>Copyright &copy; 2021 <Link to="#">iCubicle</Link>.</strong> All rights
                reserved.
            </footer>

            {/* <!-- Control Sidebar --> */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* <!-- Control sidebar content goes here --> */}
            </aside>
            {/* <!-- /.control-sidebar -->   */}
        </div>

    )
}

export default ShareLink