import React, { Component } from 'react'
import ResetPassword from './ResetPassword'
import { connect } from 'react-redux'
import { serverVerify, serverResetPassword } from './../../actions/AuthAction'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const $ = require("jquery");

class ResetPasswordContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reset: {},
        }

        this.verify = this.verify.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState) { // this is called anytime there is changes in the props/state

        if (prevProps.auth.rand !== this.props.auth.rand) {
            // show verification modal
            $("#mod").click();

            if (this.props.auth.rand === []) {
                this.setState({reset: {}});
            }
        }
    }

    verify = () => {

        if (!this.state.reset.email) {
            toast.error("Please enter your email.", {
                position: 'top-center',
                autoClose: 3000
            });
            return
        }

        let obj = {
            email: this.state.reset.email
        }

        this.props.serverVerify(obj);
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            reset: {                   // object that we want to update
                ...prevState.reset,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));
    }

    resetPassword = () => {
        if (!this.state.reset.code || !this.state.reset.password || !this.state.reset.password2) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });
            return
        }

        if (this.state.reset.password !== this.state.reset.password2) {
            toast.error("The passwords you entered do not match", {
                position: 'top-center',
                autoClose: 3000
            });
            return
        }

        this.props.serverResetPassword(this.state.reset);
    }

    render() {
        return (
            <>
                <ResetPassword
                    {...this.state}
                    handleChange={this.handleChange}
                    verify={this.verify}
                    isLoading={this.props.auth.isLoading}
                    reset_password={this.resetPassword}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { serverVerify, serverResetPassword })(ResetPasswordContainer)
