const types = {
    LOADING: 'LOADING',
    CLEAR_DATA: 'CLEAR_DATA',
    CHECK_USER: 'CHECK_USER',
    SERVER_URL: 'https://intranet.htu.edu.gh/',
    SERVER_URL_ALT: 'https://files.htu.edu.gh/',
    // SERVER_URL: 'http://localhost:9000/',
    SOCKET_URL: 'https://io.icubicle.net/',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    DO_LOGOUT: 'DO_LOGOUT',
    REASSIGN_PRIVILEGES: 'REASSIGN_PRIVILEGES',
    LOGIN_VERIFY: 'LOGIN_VERIFY',

    CONTACTS_LOADING: 'CONTACTS_LOADING',
    CONTACTS_FAIL: 'CONTACTS_FAIL',
    ALL_CONTACTS: 'ALL_CONTACTS',
    CONTACTS_SUCCESS: 'CONTACTS_SUCCESS',

    COMPANY_FAIL: 'COMPANY_FAIL',
    ALL_COMPANIES: 'ALL_COMPANIES',
    COMPANY_SUCCESS: 'COMPANY_SUCCESS',

    GROUP_FAIL: 'GROUP_FAIL',
    ALL_GROUPS: 'ALL_GROUPS',
    GROUP_SUCCESS: 'GROUP_SUCCESS',

    FILES_LOADING: 'FILES_LOADING',
    FILES_FAIL: 'FILES_FAIL',
    ALL_FILES: 'ALL_FILES',
    FILES_SUCCESS: 'FILES_SUCCESS',
    ALL_TRASH: 'ALL_TRASH',
    ALL_SHARED: 'ALL_SHARED',
    ALL_RECEIVED: 'ALL_RECEIVED',
    TAGS: 'TAGS',
    SEARCHED_FILES: 'SEARCHED_FILES',
    LINK_FILES: 'LINK_FILES',

    PRIVILEGES_LOADING: 'PRIVILEGES_LOADING',
    PRIVILEGES_FAIL: 'PRIVILEGES_FAIL',
    PRIVILEGES: 'PRIVILEGES',
    PRIVILEGES_SUCCESS: 'PRIVILEGES_SUCCESS',
}

export default types
