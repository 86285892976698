import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.FILES_LOADING,
            payload: action
        })
    }
}

export const serverGetAllFiles = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_all_files_new',
            data: {},
            method: 'post'
        }).then((response) => {
                dispatch({
                    type: types.ALL_FILES,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverUploadFiles = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/upload_files_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });

                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverUploadUrl = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/upload_url_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverCreateFile = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/create_file_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverCreateFolder = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/create_folder_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverDeleteFiles = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/delete_files_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverRenameFile = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/rename_file_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverCopyFiles = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/copy_files_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverMoveFiles = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/move_files_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverShareInApp = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/share_in_app_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverShareViaLink = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/share_via_link_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetAllTrash = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_all_trash_new',
            data: {},
            method: 'post'
        }).then((response) => {
                dispatch({
                    type: types.ALL_TRASH,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverDeleteTrash = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/delete_trash_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverRestoreTrash = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/restore_trash_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetSharedFiles = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_shared_files_new',
            data: {},
            method: 'post'
        }).then((response) => {
                dispatch({
                    type: types.ALL_SHARED,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverUnshareFiles = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/unshare_files_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverUpdateShareRules = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/update_share_rules_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetReceivedFiles = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_received_files_new',
            data: {},
            method: 'post'
        }).then((response) => {
                dispatch({
                    type: types.ALL_RECEIVED,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverGetReceivedChildren = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_received_children_new',
            data,
            method: 'post'
        }).then((response) => {
                dispatch({
                    type: types.ALL_RECEIVED,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverGetTags = (token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        axios({
            headers,
            url: types.SERVER_URL + 'get_tags',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TAGS,
                    payload: response.data
                });

                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
                return
            }
        }).catch((err) => {
            return dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
        })
    }
}

export const serverDoSearch = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/do_search_new',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.SEARCHED_FILES,
                    payload: response.data
                });

                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
                return
            }
        }).catch((err) => {
            return dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
        })
    }
}

export const serverGetLinkFiles = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_link_jambo',
            data: {},
            method: 'post'
        }).then((response) => {
                dispatch({
                    type: types.LINK_FILES,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverGetLinkChildren = (data, token) => {
    return async (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: types.SERVER_URL + 'fm/get_link_cjambo',
            data,
            method: 'post'
        }).then((response) => {
                dispatch({
                    type: types.LINK_FILES,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverDeleteLinkFiles = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/del_ln',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverRenameLinkFile = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/rename_ln_f',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverUploadLinkFiles = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/upload_ln',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });

                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });

                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverUploadLinkUrl = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/upload_ln_u',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverCreateLinkFile = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/create_ln_f',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverCreateLinkFolder = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.FILES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: types.SERVER_URL + 'fm/create_ln_fo',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.FILES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.FILES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.FILES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.FILES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.FILES_LOADING,
                payload: "false"
            });
            return
        })
    }
}