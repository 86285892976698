import React from 'react'
import { Link } from "react-router-dom";
// import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import types from '../../types';
import Select from 'react-select'
import { getTrans as t } from '../../translations';

// const data = {
//     id: 'root',
//     name: 'Parent',
//     children: [
//         {
//             id: '1',
//             name: 'Child - 1',
//         },
//         {
//             id: '3',
//             name: 'Child - 3',
//             children: [
//                 {
//                     id: '4',
//                     name: 'Child - 4',
//                 },
//             ],
//         },
//     ],
// };

const privilege_list = [
    {
        "label": "Upload Files",
        "value": "upload"
    },
    {
        "label": "Create Folder",
        "value": "new_folder"
    },
    {
        "label": "Delete Files",
        "value": "delete"
    },
    {
        "label": "Rename Files",
        "value": "rename"
    },
    {
        "label": "Preview Files",
        "value": "preview"
    },
    {
        "label": "Download Files",
        "value": "download"
    }
];

const renderTree = (nodes) => (
    <TreeItem key={nodes.fid + ""} nodeId={nodes.fid + ""} label={nodes.filename}>
        {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
    </TreeItem>
);

const Files = (props) => {

    // console.log("xxx", props.files.tree_folders);

    return (
        <>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12" style={{ marginTop: 10 }}>
                            <div className="card">
                                <div className="card-body" style={{ padding: 0, fontSize: 14 }}>
                                    <ul className="list-group list-group-horizontal-xl">
                                        <li className="list-group-item dropdown" style={{ padding: 8 }}>
                                            {
                                                !props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && props.c_state.breadcrumbs.length && props.check_privilege("upload") ?
                                                    <>
                                                        <Link to="#" id="dropdownSubMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="card-link"><i className='fa fa-plus'></i> {t('File', props.auth.language)}</Link>
                                                        <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                                                            <li><Link to="#" data-toggle="modal" data-target="#upload_file" className="dropdown-item">{t('My Computer', props.auth.language)} </Link></li>
                                                            <li><Link to="#" data-toggle="modal" data-target="#upload_url" className="dropdown-item">{t('URL', props.auth.language)}</Link></li>

                                                            <li className="dropdown-divider"></li>
                                                            <li><Link to="#" data-toggle="modal" data-target="#create_word" className="dropdown-item"><img src="static/assets/dist/img/word.png" height="20" alt="word_logo" /> {t('Word Document', props.auth.language)}</Link></li>
                                                            <li><Link to="#" data-toggle="modal" data-target="#create_powerpoint" className="dropdown-item"><img src="static/assets/dist/img/powerpoint.png" height="20" alt="powerpoint_logo" /> {t('Powerpoint', props.auth.language)}</Link></li>
                                                            <li><Link to="#" data-toggle="modal" data-target="#create_excel" className="dropdown-item"><img src="static/assets/dist/img/excel.png" height="20" alt="excel_logo" /> {t('Spreadsheet', props.auth.language)}</Link></li>
                                                        </ul>
                                                    </>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-plus'></i> {t('File', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                !props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && props.c_state.breadcrumbs.length && props.check_privilege("new_folder") ?
                                                    <Link to="#" data-toggle="modal" data-target="#new_folder" className="card-link"><i className='fa fa-plus'></i> {t('Folder', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-plus'></i> {t('Folder', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                    <Link to="#" onClick={props.copy} className="card-link"><i className='fa fa-copy'></i> {t('Copy', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-copy'></i> {t('Copy', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                    <Link to="#" onClick={props.move} className="card-link"><i className='fa fa-arrows-alt'></i> {t('Move', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-arrows-alt'></i> {t('Move', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.copy || props.c_state.move ?
                                                    <Link to="#" onClick={props.paste} className="card-link"><i className='fa fa-paste'></i> {t('Paste', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-paste'></i> {t('Paste', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length === 1 && props.c_state.files_selected[0].type === "file" && !props.c_state.copy && !props.c_state.move && !props.c_state.trash ?
                                                    <Link to="#" onClick={props.download_files} className="card-link"><i className='fa fa-download'></i> {t('Download', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-download'></i> {t('Download', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                    <Link to="#" onClick={props.trash_files} className="card-link"><i className='fa fa-trash'></i> {t('Move To Trash', props.auth.language)}</Link>
                                                    : props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && props.c_state.trash ?
                                                        <Link to="#" onClick={props.delete_files} className="card-link"><i className='fa fa-times'></i> {t('Delete', props.auth.language)}</Link>
                                                        :
                                                        <span style={{ color: '#B2BEB5' }}><i className='fa fa-trash'></i> {t('Move To Trash', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length === 1 && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared ?
                                                    <Link to="#" data-toggle="modal" data-target="#rename_file" className="card-link"><i className='fa fa-file'></i> {t('Rename', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-file'></i> {t('Rename', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length === 1 && props.c_state.files_selected[0].type === 'file' && !props.c_state.copy && !props.c_state.move && !props.c_state.trash ?
                                                    <Link to="#" onClick={() => props.set_file(props.c_state.files_selected[0])} data-toggle="modal" data-target="#view_file" className="card-link"><i className='fa fa-eye'></i> {t('View', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-eye'></i> {t('View', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                    <Link to="#" data-toggle="modal" data-target="#share_in_app" className="card-link"><i className='fa fa-share'></i> {t('Share (In App)', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-share'></i> {t('Share (In App)', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                    <Link to="#" data-toggle="modal" data-target="#share_via_link" className="card-link"><i className='fa fa-share'></i> {t('Share (Via Link)', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-share'></i> {t('Share (Via Link)', props.auth.language)}</span>
                                            }
                                        </li>
                                        <li className="list-group-item" style={{ padding: 8 }}>
                                            {
                                                props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && props.c_state.trash && !props.c_state.shared ?
                                                    <Link to="#" onClick={props.restore} className="card-link"><i className='fa fa-undo'></i> {t('Restore', props.auth.language)}</Link>
                                                    :
                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-undo'></i> {t('Restore', props.auth.language)}</span>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.row --> */}
                </div>
                {/* <!-- /.container-fluid --> */}

                {/* <!-- Main content --> */}
                <div className="content">
                    <div className="row mb-2">
                        <div className="col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 style={{ padding: 10 }}>{t('My Folders', props.auth.language)}</h5>
                                    <TreeView
                                        aria-label="rich object"
                                        defaultCollapseIcon={<><ExpandMoreIcon /> <i className="fa fa-folder" style={{ marginRight: 20 }}></i></>}
                                        defaultExpanded={[props.c_state.current_directory + ""]}
                                        defaultExpandIcon={<><ChevronRightIcon /> <i className="fa fa-folder" style={{ marginRight: 20 }}></i> </>}
                                        defaultChecked={props.c_state.current_directory + ""}
                                        defaultEndIcon={<i className="fa fa-folder"></i>}
                                        sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                        // onNodeToggle={props.handle_tree_select}
                                        onNodeSelect={props.click_tree}
                                    >
                                        {renderTree(props.files.tree)}
                                    </TreeView>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="card">
                                <div className="card-body">
                                    {
                                        props.files.isLoading === "true" ?
                                            <div align="center">
                                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>{t('We are loading your files. Please wait...', props.auth.language)}</p></span>
                                            </div>
                                            :
                                            <>
                                                <div className="col-sm-12 row mt-2">
                                                    <ol className="breadcrumb float-sm-left">
                                                        {
                                                            props.c_state.trash ?
                                                                <>
                                                                    <li className="breadcrumb-item"><Link to="#" onClick={props.go_home}>{t('Home', props.auth.language)}</Link></li>
                                                                    <li className="breadcrumb-item"><Link to="#">{t('Trash', props.auth.language)}</Link></li>
                                                                </>
                                                                :
                                                                props.c_state.shared ?
                                                                    <>
                                                                        <li className="breadcrumb-item"><Link to="#" onClick={props.go_home}>{t('Home', props.auth.language)}</Link></li>
                                                                        <li className="breadcrumb-item"><Link to="#">{t('Shared', props.auth.language)}</Link></li>
                                                                    </>
                                                                    :
                                                                    props.c_state.received ?
                                                                        <>
                                                                            <li className="breadcrumb-item"><Link to="#" onClick={props.go_home}>{t('Home', props.auth.language)}</Link></li>
                                                                            <li className="breadcrumb-item"><Link to="#" onClick={props.received_files}>{t('Received', props.auth.language)}</Link></li>
                                                                            {
                                                                                props.c_state.breadcrumbs.map((data, index) => {
                                                                                    return (
                                                                                        <li key={index} className="breadcrumb-item"><Link to="#" onClick={() => props.click_breadcrumbs(index)}>{data.filename === '/' ? t('Home', props.auth.language) : data.filename}</Link></li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                        :
                                                                        props.c_state.breadcrumbs.map((data, index) => {
                                                                            return (
                                                                                <li key={index} className="breadcrumb-item"><Link to="#" onClick={() => props.click_breadcrumbs(index)}>{data.filename === '/' ? t('Home', props.auth.language) : data.filename}</Link></li>
                                                                            )
                                                                        })
                                                        }
                                                    </ol>
                                                </div>
                                                <div className="row">
                                                    <ul className="list-group list-group-horizontal-xl" style={{ padding: 0, fontSize: 14 }}>
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            <Link to="#" className="card-link" onClick={props.show_search}><i className='fa fa-search'></i> {t('Search', props.auth.language)}</Link>
                                                        </li>
                                                        {/* <li className="list-group-item" style={{ padding: 8 }}>
                                                            <Link to="#" onClick={props.one_level_up} className="card-link"><i className='fa fa-level-up-alt'></i> One Level Up</Link>
                                                        </li> */}
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            <Link to="#" onClick={props.reload} className="card-link"><i className='fa fa-sync'></i> {t('Refresh', props.auth.language)}</Link>
                                                        </li>
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            <Link to="#" onClick={props.select_all} className="card-link"><i className='fa fa-check-square'></i> {t('Select All', props.auth.language)}</Link>
                                                        </li>
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            <Link to="#" onClick={props.unselect_all} className="card-link"><i className='fa fa-minus-square'></i> {t('Unselect All', props.auth.language)}</Link>
                                                        </li>
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            {
                                                                !props.c_state.trash ?
                                                                    <Link to="#" onClick={props.view_trash} className="card-link"><i className='fa fa-trash'></i> {t('View Trash', props.auth.language)}</Link>
                                                                    :
                                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-trash'></i> {t('View Trash', props.auth.language)}</span>
                                                            }
                                                        </li>
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            {
                                                                props.c_state.trash ?
                                                                    <Link to="#" onClick={props.empty_trash} className="card-link"><i className='fa fa-trash-alt'></i> {t('Empty Trash', props.auth.language)}</Link>
                                                                    :
                                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-trash-alt'></i> {t('Empty Trash', props.auth.language)}</span>
                                                            }
                                                        </li>
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            {
                                                                !props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.shared ?
                                                                    <Link to="#" onClick={props.shared_files} className="card-link"><i className='fa fa-share'></i> {t('Shared Files', props.auth.language)}</Link>
                                                                    :
                                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-share'></i> {t('Shared Files', props.auth.language)}</span>
                                                            }
                                                        </li>
                                                        <li className="list-group-item" style={{ padding: 8 }}>
                                                            {
                                                                !props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.received ?
                                                                    <Link to="#" onClick={props.received_files} className="card-link"><i className='fa fa-inbox'></i> {t('Received Files', props.auth.language)}</Link>
                                                                    :
                                                                    <span style={{ color: '#B2BEB5' }}><i className='fa fa-inbox'></i> {t('Received Files', props.auth.language)}</span>
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div id="collapseOne" className={props.c_state.show_search ? "collapse show" : "collapse"} data-parent="#accordion">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <span htmlFor="search_term">{t('Search Term', props.auth.language)}</span>
                                                                    <input className="form-control" id="search_term" onChange={(event) => props.handleSearch({ "field": "search_term", "value": event.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <span htmlFor="file_extension">{t('File Extension', props.auth.language)}</span>
                                                                    <Select
                                                                        options={props.c_state.file_type_list}
                                                                        name="file_extension"
                                                                        value={props.c_state.search_details.file_extension ? typeof props.c_state.search_details.file_extension !== "string" ? props.c_state.search_details.file_extension : JSON.parse(props.c_state.search_details.file_extension.replace(/'/g, '"')) : ''}
                                                                        id="custom_select"
                                                                        onChange={(value) => props.handleSearch({ "field": "file_extension", "value": value })}
                                                                        isMulti
                                                                        style={{ width: '100%' }}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder={t('Select', props.auth.language)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <div className="row">
                                                                        <div className='col-6'>
                                                                            <span>{t('From (Date Created)', props.auth.language)}</span>
                                                                            <input className="form-control" id="date_created_from" type="date" onChange={(event) => props.handleSearch({ "field": "date_created_from", "value": event.target.value })} />
                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <span>{t('To (Date Created)', props.auth.language)}</span>
                                                                            <input className="form-control" id="date_created_to" type="date" onChange={(event) => props.handleSearch({ "field": "date_created_to", "value": event.target.value })} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="form-group">
                                                                    <span htmlFor="tags">{t('Tags', props.auth.language)}</span>
                                                                    <Select
                                                                        options={props.c_state.tags_dropdown}
                                                                        name="tags"
                                                                        value={props.c_state.search_details.tags ? props.c_state.search_details.tags : ''}
                                                                        id="custom_select"
                                                                        onChange={(value) => props.handleSearch({ "field": "tags", "value": value })}
                                                                        isMulti
                                                                        style={{ width: '100%' }}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder={t('Select', props.auth.language)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <span>{t('Filter', props.auth.language)}: </span>
                                                            </div>
                                                            <div className="col-10">
                                                                <div className="form-group row">
                                                                    <div className="form-check col-3">
                                                                        <input className="form-check-input" type="radio" id="filter_all" name="radio1" onChange={(event) => props.handleSearch({ "field": "filter", "value": "all" })} checked={props.c_state.search_details.filter === "all" ? true : false} />
                                                                        <label className="form-check-label" htmlFor="filter_all">{t('All', props.auth.language)}</label>
                                                                    </div>
                                                                    <div className="form-check col-3">
                                                                        <input className="form-check-input" type="radio" id="filter_file_name" name="radio1" onChange={(event) => props.handleSearch({ "field": "filter", "value": "file_name" })} checked={props.c_state.search_details.filter === "file_name" ? true : false} />
                                                                        <label className="form-check-label" htmlFor="filter_file_name">{t('File Name', props.auth.language)}</label>
                                                                    </div>
                                                                    <div className="form-check col-3">
                                                                        <input className="form-check-input" id="filter_folder_name" type="radio" name="radio1" onChange={(event) => props.handleSearch({ "field": "filter", "value": "folder_name" })} checked={props.c_state.search_details.filter === "folder_name" ? true : false} />
                                                                        <label className="form-check-label" htmlFor="filter_folder_name">{t('Folder Name', props.auth.language)}</label>
                                                                    </div>
                                                                    <div className="form-check col-3">
                                                                        <input className="form-check-input" id="filter_file_contents" type="radio" name="radio1" onChange={(event) => props.handleSearch({ "field": "filter", "value": "file_contents" })} checked={props.c_state.search_details.filter === "file_contents" ? true : false} />
                                                                        <label className="form-check-label" htmlFor="filter_file_contents">{t('File Contents', props.auth.language)}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div align="center">
                                                            <button className="btn btn-primary" onClick={props.do_search}><i className="fa fa-search"></i> {t('Search', props.auth.language)}</button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div id="my-context-area" className="table-responsive" style={{ padding: 20, paddingTop: 0, height: 300, overflowY: 'scroll' }}>
                                                        <table className="table table-hover text-nowrap">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>{t('Name', props.auth.language)}</th>
                                                                    <th>{t('Size', props.auth.language)}</th>
                                                                    {
                                                                        props.c_state.shared ?
                                                                            <th>{t('Date Shared', props.auth.language)}</th>
                                                                            :
                                                                            props.c_state.received ?
                                                                                <th>{t('Date Received', props.auth.language)}</th>
                                                                                :
                                                                                <th>{t('Last Modified', props.auth.language)}</th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    props.c_state.file_list.map((data, index) => {
                                                                        return (
                                                                            <tr key={index} onContextMenu={() => props.main_context_menu(data)}>
                                                                                <td><input className="" type="checkbox" checked={props.c_state.selected_files[data.fid] ? true : false} onChange={() => props.select_files(data)} /></td>
                                                                                {
                                                                                    data.type === "file" ?
                                                                                        <>
                                                                                            {
                                                                                                props.c_state.shared ?
                                                                                                    <td>&nbsp;<Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_shared_info" onClick={() => props.share_info(data)}><i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i>{data.filename.substring(0, 45)}{data.filetype}</Link></td>
                                                                                                    :
                                                                                                    <td>&nbsp;<Link to="#" style={{ color: props.c_state.trash ? 'red' : 'black' }} data-toggle="modal" data-target="#view_file" onClick={() => props.set_file(data)}><i style={{ color: 'blue' }} className="fa fa-file-alt"> &nbsp;</i>{data.filename.substring(0, 45)}{data.filetype}</Link></td>
                                                                                            }
                                                                                            {
                                                                                                props.c_state.shared ?
                                                                                                    <td><Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_shared_info" onClick={() => props.share_info(data)}>{data.filesize} {data.filesize ? 'MB' : ''}</Link></td>
                                                                                                    :
                                                                                                    <td><Link to="#" style={{ color: props.c_state.trash ? 'red' : 'black' }} data-toggle="modal" data-target="#view_file" onClick={() => props.set_file(data)}>{data.filesize} {data.filesize ? 'MB' : ''}</Link></td>
                                                                                            }
                                                                                            {
                                                                                                props.c_state.shared ?
                                                                                                    <td><Link to="#" style={{ color: props.c_state.trash ? 'red' : 'black' }} data-toggle="modal" data-target="#view_shared_info" onClick={() => props.share_info(data)}>{data.created_at}</Link></td>
                                                                                                    :
                                                                                                    <td><Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_file" onClick={() => props.set_file(data)}>{data.updated_at}</Link></td>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {
                                                                                                props.c_state.shared ?
                                                                                                    <td>&nbsp;<Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_shared_info" onClick={() => props.share_info(data)}><i style={{ color: 'red' }} className="fa fa-folder"> &nbsp;</i>{data.filename.substring(0, 45)}{data.filetype}</Link></td>
                                                                                                    :
                                                                                                    <td>&nbsp;<Link to="#" style={{ color: props.c_state.trash ? 'red' : 'black' }} onClick={() => props.enter_folder(data)}><i style={{ color: 'red' }} className="fa fa-folder"> &nbsp;</i>{data.filename.substring(0, 45)}{data.filetype}</Link></td>
                                                                                            }
                                                                                            {
                                                                                                props.c_state.shared ?
                                                                                                    <td><Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_shared_info" onClick={() => props.share_info(data)}>{data.filesize} {data.filesize ? 'MB' : ''}</Link></td>
                                                                                                    :
                                                                                                    <td><Link to="#" style={{ color: props.c_state.trash ? 'red' : 'black' }} onClick={() => props.enter_folder(data)}>{data.filesize} {data.filesize ? 'MB' : ''}</Link></td>
                                                                                            }
                                                                                            {
                                                                                                props.c_state.shared ?
                                                                                                    <td><Link to="#" style={{ color: props.c_state.trash ? 'red' : 'black' }} data-toggle="modal" data-target="#view_shared_info" onClick={() => props.share_info(data)}>{data.created_at}</Link></td>
                                                                                                    :
                                                                                                    <td><Link to="#" style={{ color: props.c_state.trash ? 'red' : 'black' }} onClick={() => props.enter_folder(data)}>{data.updated_at}</Link></td>

                                                                                            }
                                                                                        </>
                                                                                }
                                                                                {/* <!-- Context Menu --> */}
                                                                                <div id="context-menu">
                                                                                    {
                                                                                        props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                                                            <div class="item" data-toggle="modal" data-target="#share_via_link">
                                                                                                <i class="fa fa-share"></i> {t('Share (Via Link)', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                                                            <div class="item" data-toggle="modal" data-target="#share_in_app">
                                                                                                <i class="fa fa-share"></i> {t('Share (In App)', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }                                                                                    {
                                                                                        props.c_state.files_selected.length === 1 && props.c_state.files_selected[0].type === "file" && !props.c_state.copy && !props.c_state.move && !props.c_state.trash ?
                                                                                            <div class="item" onClick={props.download_files}>
                                                                                                <i class="fa fa-download"></i> {t('Download', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                                                            <div class="item" onClick={props.trash_files}>
                                                                                                <i class="fa fa-trash"></i> {t('Move To Trash', props.auth.language)}
                                                                                            </div>
                                                                                            : props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && props.c_state.trash ?
                                                                                                <div class="item" onClick={props.delete_files}>
                                                                                                    <i class="fa fa-times"></i> {t('Delete', props.auth.language)}
                                                                                                </div>
                                                                                                : null
                                                                                    }
                                                                                    {
                                                                                        props.c_state.files_selected.length === 1 && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared ?
                                                                                            <div class="item" data-toggle="modal" data-target="#rename_file">
                                                                                                <i class="fa fa-file"></i> {t('Rename', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }

                                                                                    <hr />
                                                                                    {
                                                                                        props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                                                            <div class="item" onClick={props.copy}>
                                                                                                <i class="fa fa-copy"></i> {t('Copy', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && !props.c_state.trash && !props.c_state.shared && !props.c_state.received ?
                                                                                            <div class="item" onClick={props.move}>
                                                                                                <i class="fa fa-arrows-alt"></i> {t('Move', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.c_state.copy || props.c_state.move ?
                                                                                            <div class="item" onClick={props.move}>
                                                                                                <i class="fa fa-paste"></i> {t('Paste', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        props.c_state.files_selected.length && !props.c_state.copy && !props.c_state.move && props.c_state.trash && !props.c_state.shared ?
                                                                                            <div class="item" onClick={props.restore}>
                                                                                                <i class="fa fa-undo"></i> {t('Restore', props.auth.language)}
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                {/* <!-- /Context Menu --> */}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.content-wrapper --> */}
                </div>
            </div>

            {/* Modals */}

            <div className="modal fade" id="upload_file">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Upload File', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <span>{t('Select File', props.auth.language)}</span>
                                    <input className="form-control" type="file" multiple name="files[]" onChange={(event) => props.handleChange({ "field": "upload_files", "value": event.target.files })} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_upload_files_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.upload_files}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="upload_url">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Upload File (URL)', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <span>{t('Enter URL location of file to upload', props.auth.language)}</span>
                                    <input className="form-control" type="text" name="url" placeholder="http://" onChange={(event) => props.handleChange({ "field": "upload_url", "value": event.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_upload_url_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.upload_url}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="create_word">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Create Word Document', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <span>{t('Enter File Name', props.auth.language)}</span>
                                    <input className="form-control" type="text" placeholder="File Name" onChange={(event) => props.handleChange({ "field": "create_doc_file_name", "value": event.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_word_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={() => props.create_file("Word")}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="create_excel">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Create Excel Document', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <span>{t('Enter File Name', props.auth.language)}</span>
                                    <input className="form-control" type="text" placeholder="File Name" onChange={(event) => props.handleChange({ "field": "create_doc_file_name", "value": event.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_excel_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={() => props.create_file("Excel")}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="create_powerpoint">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Create Powerpoint Document', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <span>{t('Enter File Name', props.auth.language)}</span>
                                    <input className="form-control" type="text" placeholder="File Name" onChange={(event) => props.handleChange({ "field": "create_doc_file_name", "value": event.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_powerpoint_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={() => props.create_file("Powerpoint")}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="new_folder">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Create New Folder', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <span>{t('Enter Folder Name', props.auth.language)}</span>
                                    <input className="form-control" type="text" placeholder={t('Folder Name', props.auth.language)} onChange={(event) => props.handleChange({ "field": "create_folder_name", "value": event.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_create_folder_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.create_folder}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="rename_file">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Rename File/Folder', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12">
                                <div className="form-group">
                                    <span>{t('Enter New File/Folder Name', props.auth.language)}</span>
                                    <input className="form-control" type="text" placeholder={props.c_state.files_selected.length ? props.c_state.files_selected[0].filename : ''} onChange={(event) => props.handleChange({ "field": "rename_file_name", "value": event.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_rename_file_modal" onClick={props.clear_modal_contents}>{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.rename_file}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            {
                props.c_state.files_selected.length && !props.c_state.trash ?
                    props.c_state.files_selected[0].type === "file" ?
                        <div className="modal fade" id="view_file" data-backdrop="static" tabIndex="-1" role="dialog">
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title">{t('View File', props.auth.language)}</h4>
                                        <button type="button" onClick={props.unselect_all} className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {
                                            props.c_state.files_selected.length ?
                                                props.c_state.files_selected[0].filetype === ".docx" || props.c_state.files_selected[0].filetype === ".pptx" || props.c_state.files_selected[0].filetype === ".xlsx" || props.c_state.files_selected[0].filetype === ".csv" ?
                                                    props.c_state.document_config ?
                                                        <div className="embed-responsive embed-responsive-16by9">
                                                            <iframe
                                                                className="embed-responsive-item"
                                                                title="doc_viewer"
                                                                src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.c_state.document_config)}`)}
                                                                allowFullScreen>
                                                            </iframe>
                                                        </div>
                                                        : null

                                                    : props.file_type(props.c_state.files_selected[0].filetype) === "audio" ?
                                                        <audio controls preload='auto' autoPlay width='100%' height='100%'>
                                                            <source src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`} type='audio/ogg; audio/mpeg' />
                                                        </audio>

                                                        : props.file_type(props.c_state.files_selected[0].filetype) === "image" ?
                                                            <img src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`} alt="attachment-img" width={'100%'} />

                                                            : props.file_type(props.c_state.files_selected[0].filetype) === "video" ?
                                                                <video controls preload='auto' width='100%' autoPlay height='100%'>
                                                                    <source src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`} type='video/mp4; video/webm; video/x-matroska' />
                                                                </video>

                                                                : props.c_state.files_selected[0].filetype === ".pdf" ?
                                                                    <div className="embed-responsive embed-responsive-16by9">
                                                                        <iframe
                                                                            className="embed-responsive-item"
                                                                            title="pdf_viewer"
                                                                            src={`${types.SERVER_URL}static/file_manager/files/${props.c_state.files_selected[0].fid}${props.c_state.files_selected[0].filetype}`}
                                                                            allowFullScreen>
                                                                        </iframe>
                                                                    </div>

                                                                    : <p align="center">{t('This file cannot be previewed', props.auth.language)}. <Link to="#" onClick={props.download_files}>{t('Click here to download', props.auth.language)}.</Link></p>
                                                : null
                                        }
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <button type="button" onClick={props.unselect_all} className="btn btn-default" data-dismiss="modal" id="dismiss_view_file_modal">{t('Close', props.auth.language)}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    : null
            }

            {
                !props.c_state.shared ?
                    <div className="modal fade" id="share_in_app">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Share (In App)', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <span>{t('Share With', props.auth.language)}</span>
                                                {
                                                    !props.c_state.file_details.sharetype || props.c_state.file_details.sharetype === "inapp" ?
                                                        <Select
                                                            options={props.c_state.contact_list}
                                                            name="recipients"
                                                            value={props.c_state.file_details.recipients ? typeof props.c_state.file_details.recipients !== "string" ? props.c_state.file_details.recipients : JSON.parse(props.c_state.file_details.recipients.replace(/'/g, '"')) : ''}
                                                            id="custom_select"
                                                            onChange={(value) => props.handleChange({ "field": "recipients", "value": value })}
                                                            isMulti
                                                            style={{ width: '100%' }}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder={t('Select Contact', props.auth.language)}
                                                        />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Expiry Date', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('Select an expiry date for the files you wish to share. Recipients will not be able to access the file after expiry date. Leave empty for no expiry date', props.auth.language)}></i></span>
                                                <input className="form-control" type="date" onChange={(event) => props.handleChange({ "field": "expiry_date", "value": event.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Maximum Access', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('You can enter a number to specify the number of times the files can be accessed by the recipients', props.auth.language)}></i></span>
                                                <input className="form-control" type="number" onChange={(event) => props.handleChange({ "field": "max_access", "value": event.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Password', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('Set a password on the files you wish to share. Leave empty for no password', props.auth.language)}></i></span>
                                                <input className="form-control" type="text" onChange={(event) => props.handleChange({ "field": "password", "value": event.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Privileges', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('You can set custom privileges for the files/folders you share. Leave empty to allow for all privileges', props.auth.language)}></i></span>
                                                <Select
                                                    options={privilege_list}
                                                    name="actions"
                                                    value={props.c_state.file_details.actions ? typeof props.c_state.file_details.actions !== "string" ? props.c_state.file_details.actions : JSON.parse(props.c_state.file_details.actions.replace(/'/g, '"')) : ''}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "actions", "value": value })}
                                                    isMulti
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select Privilege"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Upload Size Limit (MB)', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('This field indicates that the recipients will not be able to upload files with size greater than the one specified in this field. Leave empty for unlimited file size', props.auth.language)}></i></span>
                                                <input className="form-control" type="text" onChange={(event) => props.handleChange({ "field": "upload_size_limit", "value": event.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Acceptable Files', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('Any options chosen in this list will allow the recipients to only upload files with the selected extensions. Leave empty to allow for upload of all types of files', props.auth.language)}></i></span>
                                                <Select
                                                    options={props.c_state.file_type_list}
                                                    name="upload_acceptable_file"
                                                    value={props.c_state.file_details.upload_acceptable_file ? typeof props.c_state.file_details.upload_acceptable_file !== "string" ? props.c_state.file_details.upload_acceptable_file : JSON.parse(props.c_state.file_details.upload_acceptable_file.replace(/'/g, '"')) : ''}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "upload_acceptable_file", "value": value })}
                                                    isMulti
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <span>{t('File Note', props.auth.language)}</span>
                                                <textarea className="form-control" type="text" onChange={(event) => props.handleChange({ "field": "file_note", "value": event.target.value })}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_share_in_app_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.share_in_app}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            <div className="modal fade" id="share_via_link">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('Share (Via Link)', props.auth.language)}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <span>{t('Expiry Date', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('Select an expiry date for the files you wish to share. Recipients will not be able to access the file after expiry date. Leave empty for no expiry date', props.auth.language)}></i></span>
                                        <input className="form-control" type="date" onChange={(event) => props.handleChange({ "field": "expiry_date", "value": event.target.value })} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <span>{t('Maximum Access', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('You can enter a number to specify the number of times the files can be accessed by the recipients', props.auth.language)}></i></span>
                                        <input className="form-control" type="number" onChange={(event) => props.handleChange({ "field": "max_access", "value": event.target.value })} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <span>{t('Password', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('Set a password on the files you wish to share. Leave empty for no password', props.auth.language)}></i></span>
                                        <input className="form-control" type="text" onChange={(event) => props.handleChange({ "field": "password", "value": event.target.value })} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <span>{t('Privileges', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('You can set custom privileges for the files/folders you share. Leave empty to allow for all privileges', props.auth.language)}></i></span>
                                        <Select
                                            options={privilege_list}
                                            name="actions"
                                            value={props.c_state.file_details.actions ? typeof props.c_state.file_details.actions !== "string" ? props.c_state.file_details.actions : JSON.parse(props.c_state.file_details.actions.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "actions", "value": value })}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select Privilege"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <span>{t('Upload Size Limit (MB)', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('This field indicates that the recipients will not be able to upload files with size greater than the one specified in this field. Leave empty for unlimited file size', props.auth.language)}></i></span>
                                        <input className="form-control" type="text" onChange={(event) => props.handleChange({ "field": "upload_size_limit", "value": event.target.value })} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <span>{t('Acceptable Files', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t('Any options chosen in this list will allow the recipients to only upload files with the selected extensions. Leave empty to allow for upload of all types of files', props.auth.language)}></i></span>
                                        <Select
                                            options={props.c_state.file_type_list}
                                            name="upload_acceptable_file"
                                            value={props.c_state.file_details.upload_acceptable_file ? typeof props.c_state.file_details.upload_acceptable_file !== "string" ? props.c_state.file_details.upload_acceptable_file : JSON.parse(props.c_state.file_details.upload_acceptable_file.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "upload_acceptable_file", "value": value })}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <span>{t('File Note', props.auth.language)}</span>
                                        <textarea className="form-control" type="text" onChange={(event) => props.handleChange({ "field": "file_note", "value": event.target.value })}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_share_via_link_modal">{t('Close', props.auth.language)}</button>
                            <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.share_via_link}>{t('Save changes', props.auth.language)}</button></span>
                        </div>
                    </div>
                </div>
            </div>

            {
                props.c_state.shared ?
                    <div className="modal fade" id="view_shared_info">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{t('Share Info', props.auth.language)}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <button type="button" onClick={props.unshare_files} className="btn btn-sm btn-block btn-info">{t('Unshare', props.auth.language)}</button>
                                        </div>
                                        <div className="col-6">
                                            <button type="button" onClick={() => props.go_to_source(props.c_state.file_details.parent)} className="btn btn-sm btn-block btn-info">{t('Locate This File', props.auth.language)}</button>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <span><strong>{t('File/Folder Name', props.auth.language)} :</strong> {props.c_state.file_details.filename}  {props.c_state.file_details.filetype}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                {
                                                    props.c_state.file_details.sharetype === "inapp" ?
                                                        <>
                                                            <span>{t('Shared With', props.auth.language)}</span>
                                                            <Select
                                                                options={props.c_state.contact_list}
                                                                name="recipients"
                                                                value={props.c_state.file_details.recipients ? typeof props.c_state.file_details.recipients !== "string" ? props.c_state.file_details.recipients : JSON.parse(props.c_state.file_details.recipients.replace(/'/g, '"')) : ''}
                                                                id="custom_select"
                                                                onChange={(value) => props.handleChange({ "field": "recipients", "value": value })}
                                                                isMulti
                                                                style={{ width: '100%' }}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Select Contact"
                                                                isDisabled={true}
                                                            />
                                                        </>
                                                        :
                                                        <span><strong>{t('Link Generated', props.auth.language)} :</strong> {types.SERVER_URL_ALT + 'share_link?i=' + props.c_state.file_details.recipients} &nbsp;&nbsp;&nbsp;<Link to="#"><i className="fa fa-paste text-primary" onClick={() => props.copy_link(types.SERVER_URL_ALT + 'share_link?i=' + props.c_state.file_details.recipients)}></i></Link></span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Expiry Date', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t("Select an expiry date for the files you wish to share. Recipients will not be able to access the file after expiry date. Leave empty for no expiry date", props.auth.language)}></i></span>
                                                <input className="form-control" type="date" value={props.c_state.file_details.expiry_date} onChange={(event) => props.handleChange({ "field": "expiry_date", "value": event.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Maximum Access', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t("You can enter a number to specify the number of times the files can be accessed by the recipients", props.auth.language)}></i></span>
                                                <input className="form-control" type="number" value={props.c_state.file_details.max_access} onChange={(event) => props.handleChange({ "field": "max_access", "value": event.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Password', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t("Set a password on the files you wish to share. Leave empty for no password", props.auth.language)}></i></span>
                                                <input className="form-control" type="text" value={props.c_state.file_details.password} onChange={(event) => props.handleChange({ "field": "password", "value": event.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Privileges', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t("You can set custom privileges for the files/folders you share. Leave empty to allow for all privileges", props.auth.language)}></i></span>
                                                <Select
                                                    options={privilege_list}
                                                    name="actions"
                                                    value={props.c_state.file_details.actions ? typeof props.c_state.file_details.actions !== "string" ? props.c_state.file_details.actions : JSON.parse(props.c_state.file_details.actions.replace(/'/g, '"')) : ''}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "actions", "value": value })}
                                                    isMulti
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select Privilege"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Upload Size Limit (MB)', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t("This field indicates that the recipients will not be able to upload files with size greater than the one specified in this field. Leave empty for unlimited file size", props.auth.language)}></i></span>
                                                <input className="form-control" type="text" value={props.c_state.file_details.upload_size_limit} onChange={(event) => props.handleChange({ "field": "upload_size_limit", "value": event.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <span>{t('Acceptable Files', props.auth.language)} <i style={{ fontSize: 10 }} className="fa fa-question-circle" data-toggle="tooltip" data-placement="top" title={t("Any options chosen in this list will allow the recipients to only upload files with the selected extensions. Leave empty to allow for upload of all types of files", props.auth.language)}></i></span>
                                                <Select
                                                    options={props.c_state.file_type_list}
                                                    name="upload_acceptable_file"
                                                    value={props.c_state.file_details.upload_acceptable_file ? typeof props.c_state.file_details.upload_acceptable_file !== "string" ? props.c_state.file_details.upload_acceptable_file : JSON.parse(props.c_state.file_details.upload_acceptable_file.replace(/'/g, '"')) : ''}
                                                    id="custom_select"
                                                    onChange={(value) => props.handleChange({ "field": "upload_acceptable_file", "value": value })}
                                                    isMulti
                                                    style={{ width: '100%' }}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder={t('Select', props.auth.language)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <span>{t('File Note', props.auth.language)}</span>
                                                <textarea className="form-control" type="text" value={props.c_state.file_details.file_note} onChange={(event) => props.handleChange({ "field": "file_note", "value": event.target.value })}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_share_info_modal">{t('Close', props.auth.language)}</button>
                                    <span>{props.files.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.files.isLoading === "true" ? true : false} onClick={props.update_share_rules}>{t('Save changes', props.auth.language)}</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }

            {/* Modals End */}
        </>
    )
}

export default Files
