import React from 'react'
// import { Link } from "react-router-dom";
import { getTrans as t } from '../../translations';

const Stats = (props) => {

    return (
        <>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="container-fluid">
                    <br />
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box">
                                <span className="info-box-icon bg-info elevation-1"><i className="fas fa-image"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('Images', props.auth.language)}</span>
                                    <span className="info-box-number">
                                        {props.c_state.image_count}
                                        {/* <small>%</small> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-headphones"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('Audios', props.auth.language)}</span>
                                    <span className="info-box-number">{props.c_state.audio_count}</span>
                                </div>
                            </div>
                        </div>

                        <div className="clearfix hidden-md-up"></div>

                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-success elevation-1"><i className="fas fa-video"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('Videos', props.auth.language)}</span>
                                    <span className="info-box-number">{props.c_state.video_count}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-file-word" style={{ color: 'white' }}></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('Documents', props.auth.language)}</span>
                                    <span className="info-box-number">{props.c_state.document_count}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box">
                                <span className="info-box-icon bg-primary elevation-1"><i className="fas fa-file-archive"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('Compressed Files', props.auth.language)}</span>
                                    <span className="info-box-number">
                                        {props.c_state.zip_count}
                                        {/* <small>%</small> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box">
                                <span className="info-box-icon bg-info elevation-1"><i className="fa fa-file-code" style={{ color: 'white' }}></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('Other Files', props.auth.language)}</span>
                                    <span className="info-box-number">
                                        {props.c_state.other_files_count}
                                        {/* <small>%</small> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box">
                                <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-folder" style={{ color: 'white' }}></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('Folders', props.auth.language)}</span>
                                    <span className="info-box-number">
                                        {props.c_state.folder_count}
                                        {/* <small>%</small> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box">
                                <span className="info-box-icon bg-default elevation-1"><i className="fas fa-list"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">{t('File Quota', props.auth.language)}</span>
                                    <span className="info-box-number">
                                        {props.auth.user ? (Math.round((parseFloat(props.auth.user.quota) + Number.EPSILON) * 100) / 100) + ' MB' : ''}
                                        <small> {t('Left', props.auth.language)}</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Stats