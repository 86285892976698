import React, { Component } from 'react'

import NavBar from './NavBar';
import Footer from './Footer';
import { connect } from 'react-redux';
import { checkUser, serverSwitchAccount, serverChangeLanguage } from './../../actions/AuthAction';
import { serverGetContacts, serverGetCompanies, serverGetGroups } from './../../actions/ContactsAction';
import { serverGetAllFiles } from '../../actions/FilesAction';
import { withRouter } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import types from '../../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            other_accounts: [],
            // socket: socketIOClient(types.SOCKET_URL, { transports: ['websocket'] })
            socket: socketIOClient.connect(types.SOCKET_URL),
            start: 1,
            finish: 100,
        }
    }

    componentDidMount() {

        if (localStorage.getItem("token")) {
            this.props.checkUser(localStorage.getItem("token"));
            this.props.serverGetAllFiles(localStorage.getItem("token"));
            this.props.serverGetContacts(localStorage.getItem("token"));
            this.props.serverGetCompanies(localStorage.getItem("token"));
            this.props.serverGetGroups(localStorage.getItem("token"));

            this.initiateSocket();
        }

        // window.addEventListener('click', function(e) {
        //     let ev = window.document.activeElement;
        //     let account_switch = window.document.getElementById("accounts-switch");

        //     console.log('ev', account_switch.classList.contains("d-none"));

        //     if (ev.id !== "accounts-switch" && !account_switch.classList.contains("d-none")) {
        //         account_switch.classList.add("d-none");
        //     }
        // })
    }

    componentDidUpdate = (prevProps, prevState) => {

    }

    initiateSocket = () => {
        // listen on socket
        this.state.socket.on('connect', (data) => {
            // console.log('socket connected');
            if (this.props.auth.user) {
                this.state.socket.on(`file_manager_notifications_${this.props.auth.user.contact_id}`, data => {
                    try {
                        if (data) {
                            toast.dark(data.msg, {
                                position: 'top-right',
                                autoClose: 5000
                            });

                            // switch and perform an action based of response from socket

                            switch (data.type) {
                                default:
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                });
            }
        });

        this.state.socket.on('disconnect', (data) => {
            // console.log('socket disconnected');
        });
    }

    otherAccounts = () => {
        if (this.props.auth.user) {
            if (this.props.auth.user.other_accounts && this.props.auth.user.other_accounts !== "None") {
                // console.log(this.props.auth.user.other_accounts);
                let other_accounts = JSON.parse(this.props.auth.user.other_accounts.replace(/'/g, '"'));
                // let arr = []
                // console.log("0", this.props.auth.user.other_accounts);
                // other_accounts.filter((data, index) => {
                //     arr.push(parseInt(data.value));
                // });
                let contacts = this.props.contacts.contacts.filter(item => item.contact_id === other_accounts.value);
                return contacts;
            }
        }

        return [];
    }

    logout = () => {
        localStorage.clear();
        window.location.reload();
    }

    switchAccount = (data) => {
        this.props.serverSwitchAccount({ email: data.email }, this.props.auth.user.token);
    }

    toggleSwitch = () => {
        let account_switch = window.document.getElementById("accounts-switch");

        if (account_switch && account_switch.classList.contains("d-none")) {
            account_switch.classList.remove("d-none");
        } else {
            account_switch.classList.add("d-none");
        }
    }

    changeLanguage = (language) => {
        this.props.serverChangeLanguage(language);   
    }

    render() {
        return (

            <div className="wrapper">
                <NavBar user={this.props.auth.user ? this.props.auth.user : ''} logout={this.logout} otherAccounts={this.otherAccounts()} switchAccount={this.switchAccount} contacts={this.props.contacts.contacts} all_files={this.props.files.all_files} auth={this.props.auth} changeLanguage={this.changeLanguage}/>
                <div>
                    {
                        this.props.children
                    }
                </div>
                <Footer />

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        contacts: { ...state.contacts },
        files: { ...state.files }
    }
}

export default connect(mapStateToProps,
    {
        checkUser,
        serverGetContacts,
        serverGetCompanies,
        serverGetGroups,
        serverSwitchAccount,
        serverGetAllFiles,
        serverChangeLanguage
    }
)(withRouter(Main))
