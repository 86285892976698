import React from 'react';
import { Link } from 'react-router-dom';

const ResetPassword = (props) => {

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        {/* <Link tp="#" className="h1"><b>i</b>Cubicle</Link> */}
                        <p><strong>File Management System</strong></p>
                        <img src="static/assets/dist/img/logo.png" alt="logo" width={100} />
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Reset Password</p>

                        <div className="input-group mb-3">
                            <input type="email" onChange={(event) => props.handleChange({ "field": "email", "value": event.target.value })} value={props.reset.email ? props.reset.email : ''} name="email" className="form-control" placeholder="Email" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" align="right">
                                <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}
                                    <button className="btn btn-sm btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={() => props.verify()}>Submit</button></span>
                            </div>
                        </div>
                        {/* <div className="social-auth-links text-center mt-2 mb-3">
                            <button className="btn btn-block btn-primary"><i className="fab fa-facebook mr-2"></i> Sign in using Facebook</button>
                            <button className="btn btn-block btn-danger"><i className="fab fa-google-plus mr-2"></i> Sign in using Google+</button>
                        </div> */}

                        <div hidden>
                            <button className="btn btn-block btn-primary" id="mod" data-toggle="modal" data-target="#reset_password_modal"><i className="fab fa-facebook mr-2"></i> Mod</button>
                        </div>

                        <p className="mb-1">
                            <Link to="/">Back To Login</Link>
                        </p>
                        {/* <p className="mb-0">
                            <Link to="#" className="text-center">Register a new membership</Link>
                        </p> */}
                    </div>
                </div>
            </div>

            {/* modals */}

            <div className="modal fade" id="reset_password_modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Reset Password</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <p>Please enter the 4-digit verification code sent to your email & phone number</p>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="name">Verification Code *</label>
                                    <input type="text" onChange={(event) => props.handleChange({ "field": "code", "value": event.target.value })} value={props.reset.code ? props.reset.code : ''} className="form-control" name="code" placeholder="Code" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="password">New Password *</label>
                                    <input type="text" onChange={(event) => props.handleChange({ "field": "password", "value": event.target.value })} value={props.reset.password ? props.reset.password : ''} className="form-control" name="password" placeholder="Password" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="password2">Repeat Password *</label>
                                    <input type="text" onChange={(event) => props.handleChange({ "field": "password2", "value": event.target.value })} value={props.reset.password2 ? props.reset.password2 : ''} className="form-control" name="password2" placeholder="Repeat Password" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_reset_password_modal">Close</button>
                            <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.reset_password}>Save changes</button></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ResetPassword